/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef } from 'react';
import { useState, useEffect } from 'react';
import { motion as m, useInView } from 'framer-motion';
import Bg from '@assets/Images/products/mm1.webp';
import Lock from '@assets/Images/products/L1.png';
import Domain from '@assets/Images/products/D1.png';
import Menu from '@assets/Images/products/D2.png';
import Graph from '@assets/Images/products/dd1.png';
import Notifications from '@assets/Images/products/Notifications.png';
import Meter from '@assets/Images/products/Meter.png';
import Meter1 from '@assets/Images/products/Meter1.png';
import Meter4 from '@assets/Images/products/Meter4.png';
import Wallet from '@assets/Images/products/Wallet.png';
import Paymentmode from '@assets/Images/products/Paymentmode.png';
import Geotagging from '@assets/Images/products/Geotagging.png';
import Analytics from '@assets/Images/products/Analytics.png';
import Ticketmgmt from '@assets/Images/products/Ticketmgmt.png';
import Appmgmt from '@assets/Images/products/Appmgmt.png';
import Serviceorder from '@assets/Images/products/Serviceorder.png';
import Timetracking from '@assets/Images/products/Timetracking.png';
import Interoperability from '@assets/Images/products/Interoperability.png';
import Protocolagnostic from '@assets/Images/products/Protocolagnostic.png';
import Assetmgmt from '@assets/Images/products/Assetmgmt.png';
import Integration from '@assets/Images/products/Integration.png';
import Jobmgmt from '@assets/Images/products/Jobmgmt.png';
import Assetlcmgmt from '@assets/Images/products/Assetlcmgmt.png';
import Inventorymgmt from '@assets/Images/products/Inventorymgmt.png';
import Assetinstall from '@assets/Images/products/Assetinstall.png';
import Distnetwork from '@assets/Images/products/Distnetwork.png';
import Assettype from '@assets/Images/products/Assettype.png';
import Compliance from '@assets/Images/products/Compliance.png';
import Scheduler from '@assets/Images/products/Scheduler.png';
import Usermenu from '@assets/Images/products/Usermenu.png';
import Domainexpert from '@assets/Images/products/Domainexpert.png';
import Roles from '@assets/Images/products/Roles.png';
import Spotbilling from '@assets/Images/products/Spotbilling.png';
import Billing from '@assets/Images/products/Billing.png';
import Onlinepayment from '@assets/Images/products/Onlinepayment.png';
import Tariff from '@assets/Images/products/Tariff.png';
import Mobileapp from '@assets/Images/products/Mobileapp.png';

// import Big from '@assets/Images/bg/s1.jpg';

import smarthome from '@assets/Images/products/m1.jpg';
import smart from '@assets/Images/products/m4.jpg';
// import Band from '@assets/Images/products/SmartEU HEAD BAND.jpg';

import Image from '@src/components/shared/Image';
import Typography from '@shared/Typography';
import {
  bottom_top_variants,
  top_bottom_variants,
} from '@src/utils/animations';
import { SliderComp } from './slider/Carsoul';

const contentList1 = [
  'SmartEU Access Management',
  'SmartEU Head End System (HES)',
  'SmartEU Meter Data Management (MDM)',
  'SmartEU Asset Management',
  'SmartEU Revenue Management System (RMS)',
  'SmartEU Request and Complaints Management (RCM)',
  'SmartEU Dashboards and Analytics',
  ];

const contentList2 = [
  'Manage Utilities Business Operations',
  'Complaints Management',
  'Asset Commissioning',
  'Consumption Tracking',
  'Remote Connection and Disconnection',
  'Appointment Scheduling',
  'Service Order Tracking',
  'Job Tracking & Update',
  'Meter Reading',
  'Bluetooth Connectivity',
];

const contentList3 = [
  'View & Update Profile Details',
  'View & Pay Bill',
  'Raise & Track Tickets',
  'View Billing History',
  'Pay Bills Online',
  'Recharge Prepaid Meters',
  'View Meter Reading History',
  'Reports & Analytics',
  'Upload Meter Reading',
  'Notifications and Alerts',
];

const sliderData = {
  0: [
    {
      title: 'Web & Mobile Access',
      content:
        ' SmartEU application is accessible through Web Browser and Mobile App',
      image: Bg,
    },
    {
      title: 'Role Based Access',
      content:
        'Menu and Screens are accessible by the users based on the Roles defined for them',

      image: Roles,
      height: '5.5rem',
    },
    {
      title: 'Domain Based Menu / Screen Access',
      content:
        ' SmartEU application is a common application for all Utilities types. However the screens and fields are accessible based on the Utilities Type',

      image: Domainexpert,
    },
    {
      title: 'User Configurable Menus',
      content:
        ' Role based permissions can be configured to ensure access to only specific screens in the menu',

      image: Usermenu,
    },
    {
      title: 'User Defineable Role',
      content:
        ' SmartEU allows user to create Roles and define permissions as per the requirements',

      image: Roles,
    },
    {
      title: 'Permission for Actions',
      content:
        'Based on the user roles we can define permissions for Actions (Like Edit, View etc) that can be performed on specific objects ',

      image: Notifications,
    },
  ],
  1: [
    {
      title: 'Auto Discovery and Registration',
      content:
        'Meters Deployed and commissioned are registered automatically in HES ',
      image: Meter,
    },
    {
      title: 'On Demand Reads',
      content:
        'Generation of On Demand Meter Reading Records like Instantaneous Profile, Block profile, Day Profile, Events, Billing Parameters, etc',

      image: Meter1,
    },
    {
      title: 'Interoperability',
      content:
        'SmartEU HES is Meter agnostic and can be deployed with Global Meter Manufacturers',

      image: Interoperability,
    },
    {
      title: 'Remote Connection and Disconnection',
      content: 'Meters can be connected and disconnected remotely from HES  ',

      image: Notifications,
    },
    {
      title: 'Critical & Non Critical Reporting',
      content:
        'HES records lot of Meter and Communication parameters which are represented in Reports and Graphical formats',

      image: Analytics,
    },
    {
      title: 'Support Multiple Communication Modes',
      content: 'HES supports various communication modes like GPRS / RF / NBIoT / LoRaWAN',

      image: Domain,
    },
    {
      title: 'Protocol Agnostic',
      content:
        'HES works well with DLMS / MODBUS and Proprietary protocols for various Meter Manufacturers',

      image: Protocolagnostic,
    },
    {
      title: 'Meter Parameters & Firmware Upgrades',
      content: 'Remote update of Meter Paramaters like Demand Integration Period, Load curtailments, Meter Firmware Upgrade for Single / Bulk Meters',

      image: Notifications,
    },
  ],
  2: [
    {
      title: 'Asset Management',
      content:
        'All Movable and Immovable Asset Types and Assets can be created. Information regarding Status, Geotagging and relationship with Consumers / Other Assets can be recorded and maintained.',
      image: Assetmgmt,
    },
    {
      title: 'Meter Reading',
      content:
        'Manual and Automated Meter Reading for the meters can be accomplished',
      image: Meter,
    },
    {
      title: 'Job Management',
      content:
        'Meter Reading jobs can be manually and autimatically created and assigned to Field teams',

      image: Jobmgmt,
    },
    {
      title: 'Scheduled Jobs',
      content:
        'Jobs can be scheduled for Meter Data Acquisition like Block Load profile, Midnight Snapshots, Billings Details etc ',

      image: Scheduler,
    },
    {
      title: 'On Demand Reads',
      content: 'On demand commands can be initiated through a User Friendly UI',

      image: Bg,
    },
    {
      title: 'Data Validation',
      content:
        'Automated Data validation against the user defined thresholds for example MD, Relay Status etc',

      image: Meter1,
    },
    {
      title: 'Notifications',
      content:
        'Notifications for user Definable Events through SMS, EMail and In App for relevant Stakeholders',

      image: Notifications,
    },
    {
      title: 'Third Party System Integration',
      content:
        'SmartEU MDM can be easily integrated with other Third Party Applications using RestAPI',

      image: Integration,
    },
  ],
  3: [
    {
      title: 'Asset Types and Assets',
      content:
        'Users can define Movable and Non Moveable assets types and assets as per requirement',
      image: Assettype,
    },
    {
      title: 'Inventory Management',
      content:
        'Inventory with specific Serial numbers and manufacturer details can be defined for various Assets. The inventory details can either be defined manually or can be uploaded through Excel sheets',

      image: Inventorymgmt,
    },
    {
      title: 'Inventory Lifecycle',
      content:
        'Inventory lifecycle process is predefined in the system. User can update the status like Commissioned, Decommissioned, Ready to Install etc',

      image: Assetlcmgmt,
    },
    {
      title: 'Geo Tag Location',
      content:
        'Address and Geo Tagging (Latitude and Longitude) details can be defined and stored as part of the asset commissioning / Decommissioning. The location history is maintained in the system and can be reviewed as and when required',
    
      image: Geotagging,
      },
    {
      title: 'Asset Installation & Commissioning',
      content:
        'Installation and commissioning of assets like Meters @ Consumer, DT, Feeder or Poles / Cable etc can be managed efficiently',

      image: Assetinstall,
    },
    {
      title: 'Distribution Network Creation',
      content:
        'User defineable Distribution Network levels are available for Water, power and gas distribution. The same information is used for Consumer Indexing for proper load management',

      image: Distnetwork,
    },
    {
      title: 'Analytical reports',
      content:
        'The system provides advance and exhaustive analytical reports and dashboards',

      image: Analytics,
    },
    {
      title: 'Logs & Audit',
      content:
        'SmartEU maintains logs and history for all the Transactions / Requests from third party applications etc. The same can be used for audits and validation',

      image: Compliance,
    },
  ],
  4: [
    {
      title: 'Prepaid and Postpaid Billing',
      content:
        'SmartEU RMS supports both Post paid and Prepaid billing. Consumer can switch between these two options based on their preference',
       image: Billing,
    },
    {
      title: 'Tariff and Rules Management',
      content:
        'System provides a highly configurable and flexible Tariff structure. Users can define various Charge Types and the rules for total bill amount calculation',

      image: Tariff,
    },
    {
      title: 'Wallet Management',
      content:
        'Automated Wallet creation for consumer to record advance payments for Prepaid billing. System also records various transactions (Like Wallet Recharge and Wallet amount usage for Power Consumption).',

      image: Wallet
    },
    {
      title: 'Spot Billing',
      content:
        'SmartEU enables the utility to take care of meter reading and Reliable Bill generation at the consumer place. Apart from SmartEU Mobile app, the application can also be integrated with various Devices for spot billing',

      image: Spotbilling,
    },
    {
      title: 'Multiple Payment Modes',
      content:
        'Bills can be paid in Cash / Cheque / DD or Online using payment gateway through Mobile App or Customer care portal. The payment receipts are generated realtime and can be downloaded   ',

      image: Paymentmode,
    },
    {
      title: 'Online Payment Tracking',
      content:
        'SmartEU is integrated with Payment Gateway for Online Payments. In case of any disputes transactions can be easily tracked by Trasnaction ID',

      image: Onlinepayment,
    },
    {
      title: 'Consumer App',
      content:
        'SmartEU Consumer Mobile App helps cosnumer to View Power Comsumption / Billing / Payments history. Apart from the Online Bill Payments and Wallet Recharges, the consumers can also raise request and complaints',
      image: Mobileapp,
    },
    {
      title: 'Notifications',
      content:
        'Notifications are enabled for predefined events. Consumers / Utility Users receives these notifications in the mobile apps / through SMS / Emails',

      image: Notifications,
    },
  ],
  5: [
    {
      title: 'Configurable Process',
      content:
        'SmartEU is highly flexible and allows Utilities to define their own processes for each Issue type.',
      image: Bg,
    },
    {
      title: 'Ticket Management',
      content:
        'Utility and Consumers can raise request and complaints for their specific or community issues.',

      image: Ticketmgmt,
    },
    {
      title: 'Ticket Tracking',
      content:
        'The tickets lifecycle can be tracked and managed through the application',

      image: Graph,
    },
    {
      title: 'Appointment Management',
      content:
        'Appointments can be setup for tickets based on consumer preferred Time.',

      image: Appmgmt,
    },
    {
      title: 'Service Order Mgmt',
      content:
        'Based on the Tickets raised by the Consumers and the Utilities, System allows utilities to Create Service Orders for the additional work required',

      image: Serviceorder,
    },
    {
      title: 'Time Tracking',
      content:
        'Digitized and Optimized processes provides 100% visibility and time tracking for each resource.',

      image: Timetracking,
    },
    {
      title: 'Configurable Notifications',
      content:
        'Flexible and Configurable role and user based Notifications in the Mobile apps  ',

      image: Notifications,
    },
    {
      title: 'Dashboards & Analytics',
      content:
        'Predefined and User configurable Reports and Dashboards helps Utility to Optimize Operations and take proper decisions.',

      image: Analytics,
    },
  ],
  6: [
    {
      title: 'Native Apps - Android and iOS',
      content:
        ' You can add more paragraphs or elements here with similar inline styles,You can add more paragraphs or elements here with similar inline styles  ',
      image: Bg,
    },
    {
      title: 'Role Based Access',
      content:
        ' You can add more paragraphs or elements here with similar inline styles,You can add more paragraphs or elements here with similar inline styles  ',

      image: Menu,
    },
    {
      title: 'Integrated Solution - Real Time Updates',
      content:
        ' You can ar inline styles,You can add more paragraphs or elements here with similar inline styles  ',

      image: Graph,
    },
    {
      title: 'Secured Access',
      content:
        ' You can add more paragraphs or elements here with similar inline styles,You can add more paragraphs or elements here with similar inline styles  ',

      image: Lock,
    },
    {
      title: 'Prague',
      content:
        ' You can add more paragraphs or elements here with similar inline styles,You can add more paragraphs or elements here with similar inline styles  ',

      image: Meter,
    },
    {
      title: 'Milan',
      content:
        ' You can ar inline styles,You can add more paragraphs or elements here with similar inline styles  ',

      image: Meter1,
    },
    {
      title: 'Hong Kong',
      content:
        ' You can add more paragraphs or elements here with similar inline styles,You can add more paragraphs or elements here with similar inline styles  ',

      image: Meter4,
    },
    {
      title: 'Notifications & Messages',
      content:
        ' You can add more paragraphs or elements here with similar inline styles,You can add more paragraphs or elements here with similar inline styles  ',

      image: Notifications,
    },
  ],
  7: [
    {
      title: 'Preconfigured Dashboards',
      content:
        ' You can add more paragraphs or elements here with similar inline styles,You can add more paragraphs or elements here with similar inline styles  ',
      image: Notifications,
    },
    {
      title: 'Real Time Data Visility',
      content:
        ' You can add more paragraphs or elements here with similar inline styles,You can add more paragraphs or elements here with similar inline styles  ',

      image: Graph,
    },
    {
      title: 'Critical and Non Critical Reports ',
      content:
        ' You can ar inline styles,You can add more paragraphs or elements here with similar inline styles  ',

      image: Menu,
    },
    {
      title: 'Graphical Representation',
      content:
        ' You can add more paragraphs or elements here with similar inline styles,You can add more paragraphs or elements here with similar inline styles  ',

      image: Analytics,
    },
    {
      title: 'User Friendly UI',
      content:
        ' You can add more paragraphs or elements here with similar inline styles,You can add more paragraphs or elements here with similar inline styles  ',

      image: Notifications,
    },
    {
      title: '100% Data Transparency',
      content:
        ' You can ar inline styles,You can add more paragraphs or elements here with similar inline styles  ',

      image: Meter,
    },
    {
      title: 'Integration with Third Party Apps',
      content:
        ' You can add more paragraphs or elements here with similar inline styles,You can add more paragraphs or elements here with similar inline styles  ',

      image: Meter1,
    },
    {
      title: 'Tools Availability for Data Matching & Modeling',
      content:
        ' You can add more paragraphs or elements here with similar inline styles,You can add more paragraphs or elements here with similar inline styles  ',

      image: Meter4,
    },
  ],
  8: [
    {
      title: 'Milan',
      content:
        ' You can add more paragraphs or elements here with similar inline styles,You can add more paragraphs or elements here with similar inline styles  ',
      image:
        'https://cdn.theculturetrip.com/wp-content/uploads/2019/04/ia_0444_city-header_milan_marianna-tomaselli-768x432.jpg',
    },
    {
      title: 'Milan',
      content:
        ' You can add more paragraphs or elements here with similar inline styles,You can add more paragraphs or elements here with similar inline styles  ',

      image:
        'https://cdn.theculturetrip.com/wp-content/uploads/2019/04/ia_0444_city-header_milan_marianna-tomaselli-768x432.jpg',
    },
    {
      title: 'Milan',
      content:
        ' You can ar inline styles,You can add more paragraphs or elements here with similar inline styles  ',

      image:
        'https://cdn.theculturetrip.com/wp-content/uploads/2019/04/ia_0444_city-header_milan_marianna-tomaselli-768x432.jpg',
    },
    {
      title: 'Hong Kong',
      content:
        ' You can add more paragraphs or elements here with similar inline styles,You can add more paragraphs or elements here with similar inline styles  ',

      image:
        'https://cdn.theculturetrip.com/wp-content/uploads/2019/04/ia_0378_hong-kong_jisu-choi_final_header-1-768x512.jpg',
    },
    {
      title: 'Prague',
      content:
        ' You can add more paragraphs or elements here with similar inline styles,You can add more paragraphs or elements here with similar inline styles  ',

      image:
        'https://cdn.theculturetrip.com/wp-content/uploads/2019/04/location-page-header-prague-greta-samuel-768x512.jpg',
    },
    {
      title: 'Milan',
      content:
        ' You can ar inline styles,You can add more paragraphs or elements here with similar inline styles  ',

      image:
        'https://cdn.theculturetrip.com/wp-content/uploads/2019/04/ia_0444_city-header_milan_marianna-tomaselli-768x432.jpg',
    },
    {
      title: 'Hong Kong',
      content:
        ' You can add more paragraphs or elements here with similar inline styles,You can add more paragraphs or elements here with similar inline styles  ',

      image:
        'https://cdn.theculturetrip.com/wp-content/uploads/2019/04/ia_0378_hong-kong_jisu-choi_final_header-1-768x512.jpg',
    },
    {
      title: 'Prague',
      content:
        ' You can add more paragraphs or elements here with similar inline styles,You can add more paragraphs or elements here with similar inline styles  ',

      image:
        'https://cdn.theculturetrip.com/wp-content/uploads/2019/04/location-page-header-prague-greta-samuel-768x512.jpg',
    },
  ],
};

function SolutionSmartEU() {
  const ref = useRef(null);
  const sliderRef = useRef(null);
  const isInView = useInView(ref);

  useEffect(() => {
    function handleClickOutside(event) {
      if (sliderRef.current && !sliderRef.current.contains(event.target)) {
        setShowSlider(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sliderRef]);

  const [activeSlider, setActiveSlider] = useState(null); // manage which slider is active
  const [showSlider, setShowSlider] = useState(false); // manage visibility of slider

  // Handling click on list item to show slider
  const handleItemClick = (idx) => {
    setActiveSlider(sliderData[idx]); // set the data for the slider based on clicked item
    setShowSlider(true); // show the slider
  };

  // Handling click outside or close action to hide slider
  const closeSlider = () => {
    setShowSlider(false);
  };

  return (
    <section ref={ref} className="py-10 px-11 bg-primary-white sm:py-2">
      <div className=" mx-auto text-center pb-4">
        <div className="flex flex-col text-center overflow-hidden w-fit mx-auto">
          <Typography
            mAnimate={{
              mVariants: bottom_top_variants,
              isInView: isInView,
              mDelay: 0,
            }}
            variant="P_SemiBold_H3"
            className="text-primary-black"
          >
            SmartEU Suite Of Products
          </Typography>
        </div>

        <Typography
          mAnimate={{
            mVariants: bottom_top_variants,
            isInView: isInView,
            mDelay: 0.1,
          }}
          // variant="P_Regular_H5"
          className=" text-dark-white mt-4"
        >
          SmartEU is a Cloud-Based Integrated Enterprise Commercial
          off-the-Shelf Suite of Products comprising of Web-Based Application,
          Mobility Solutions and Smart Devices for various stakeholders of
          Power, Water and Gas Utilities. SmartEU has been developed using
          latest Open Source technology stack. Microservices based Robust
          architecture makes it easy to Update, Deploy and Scale up. SmartEU
          helps in giving an edge to reduce AT&C losses, Increase Revenue and Improve Consumer
          Satisfaction.
        </Typography>
      </div>

      <div className="px-4 mx-auto sm:px-6 lg:px-16 max-w-7xl">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-12 lg:gap-x-16 xl:gap-x-[4.6rem]">
          <div className="relative overflow-hidden group lg:order-1 rounded-2xl lg:rounded-3xl">
            <div className="transition-all duration-200 group-hover:scale-110">
              <Image
                animate={{ mDelay: 0.3, isInView }}
                src={smart}
                className="rounded-lg object-cover w-full h-full"
              />
            </div>
          </div>
          <div className="lg:order-2">
            <div className=" overflow-hidden ">
              <Typography
                mAnimate={{
                  mVariants: top_bottom_variants,
                  isInView: isInView,
                  mDelay: 0.4,
                }}
                variant="P_SemiBold_H_T"
                className="flex text-primary-blue"
              >
              
              </Typography>
            </div>
            SmartEU platform leverages Highly Configurable Processes, Cloud
            Deployment and Highly Scalable Architecture and provides a complete
            solution comprising of following Process and Solutions.
            <div className=" overflow-hidden ">
              <Typography
                mAnimate={{
                  mVariants: top_bottom_variants,
                  isInView: isInView,
                  mDelay: 0.5,
                }}
                variant="P_Medium_H4"
                className="flex text-mid-black pt-4"
              ></Typography>
            </div>
            <Typography variant="P_Regular" className="text-light-black">
              <ul>
                {contentList1.map((el, idx) => {
                  const [isHovered, setIsHovered] = useState(false); // State to manage hover effect

                  return (
                    <li
                      key={idx}
                      style={listItemStyle(isHovered)}
                      onClick={() => handleItemClick(idx)}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      {/* <Link
                          to={`/new-screen/${idx}`}
                          className="flex items-center gap-2"
                          style={linkStyle}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        > */}
                      <span
                        className="w-[5px] h-[5px] bg-black rounded-full block"
                        style={{ marginRight: '8px', color: '#E6E6E6' }}
                      ></span>
                      {el}
                      {/* </Link> */}
                    </li>
                  );
                })}
              </ul>
            </Typography>
          </div>
        </div>
      </div>
      <br />
      <div className="px-4 mx-auto sm:px-6 lg:px-16 max-w-7xl">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-12 lg:gap-x-16 xl:gap-x-[4.6rem]">
          <div className="relative overflow-hidden group lg:order-1 rounded-2xl lg:rounded-3xl">
            <div className="transition-all duration-200 ">
              <div className=" overflow-hidden ">
                <Typography
                  mAnimate={{
                    mVariants: top_bottom_variants,
                    isInView: isInView,
                    mDelay: 0.4,
                  }}
                  variant="P_SemiBold_H_T"
                  className="flex text-primary-blue"
                >
                  SmartEU WFM Mobile App (Workforce Management )
                </Typography>
              </div>
              <div className=" overflow-hidden ">
                <Typography
                  mAnimate={{
                    mVariants: top_bottom_variants,
                    isInView: isInView,
                    mDelay: 0.5,
                  }}
                  variant="P_Medium_H4"
                  className="flex text-mid-black pt-4"
                ></Typography>
              </div>
              <Typography variant="P_Regular" className="text-light-black">
                <ul>
                  {contentList2.map((el, idx) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    const [isHovered, setIsHovered] = useState(false); // State to manage hover effect

                    return (
                      <li key={idx} style={listItemStyle(isHovered)}>
                        <div className=" overflow-hidden">
                          <m.span
                            initial="initial"
                            animate={isInView && 'visible'}
                            custom={idx * 0.09 + 0.6}
                            variants={top_bottom_variants}
                            viewport={{ once: true }}
                            key={idx}
                            className="flex items-center gap-2"
                          >
                            <span className="w-[5px] h-[5px] bg-black rounded-full block"></span>
                            {el}
                          </m.span>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </Typography>
            </div>
          </div>
          <div className="lg:order-2">
            <div className="relative overflow-hidden group lg:order-1 rounded-2xl lg:rounded-3xl">
              <div className="transition-all duration-200 group-hover:scale-110">
                <Image
                  animate={{ mDelay: 0.3, isInView }}
                  src={Bg}
                  className="object-cover w-full h-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="px-4 mx-auto sm:px-6 lg:px-16 max-w-7xl">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-12 lg:gap-x-16 xl:gap-x-[4.6rem]">
          <div className="relative overflow-hidden group lg:order-1 rounded-2xl lg:rounded-3xl">
            <div className="transition-all duration-200 group-hover:scale-110">
              <Image
                animate={{ mDelay: 0.3, isInView }}
                src={smarthome}
                className="object-cover w-full h-full"
              />
            </div>
          </div>
          <div className="lg:order-2">
            <div className=" overflow-hidden ">
              <Typography
                mAnimate={{
                  mVariants: top_bottom_variants,
                  isInView: isInView,
                  mDelay: 0.4,
                }}
                variant="P_SemiBold_H_T"
                className="flex text-primary-blue"
              >
                SmartEU Consumer Mobile App
              </Typography>
            </div>
            <div className=" overflow-hidden ">
              <Typography
                mAnimate={{
                  mVariants: top_bottom_variants,
                  isInView: isInView,
                  mDelay: 0.5,
                }}
                variant="P_Medium_H4"
                className="flex text-mid-black pt-4"
              ></Typography>
            </div>
            <Typography variant="P_Regular" className="text-light-black">
              <ul>
                {contentList3.map((el, idx) => {
                  const [isHovered, setIsHovered] = useState(false); // State to manage hover effect

                  return (
                    <li key={idx} style={listItemStyle(isHovered)}>
                      <div className=" overflow-hidden">
                        <m.span
                          initial="initial"
                          animate={isInView && 'visible'}
                          custom={idx * 0.09 + 0.6}
                          variants={top_bottom_variants}
                          viewport={{ once: true }}
                          key={idx}
                          className="flex items-center gap-2"
                        >
                          <span className="w-[5px] h-[5px] bg-black rounded-full block"></span>
                          {el}
                        </m.span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </Typography>
          </div>
        </div>
      </div>

      {showSlider && (
        <div
          className="overlay"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            opacity: showSlider ? 1 : 0, // Dynamic opacity
            transition: 'opacity 0.4s ease-in-out',
          }}
        >
          <div
            className="sliderPopup"
            ref={sliderRef}
            style={{
              position: 'relative',
              width: '80%',
              height: '70%',
              backgroundColor: 'white',
              padding: '10px',
              overflow: 'hidden',
              borderRadius: '10px',
              opacity: showSlider ? 1 : 0, // Dynamic opacity
              transition: 'opacity 0.4s ease-in-out',
              marginTop: '60px',
            }}
          >
            <SliderComp
              autoplay={true}
              autoplaySpeed={3000}
              slideNum={4}
              data={activeSlider}
              label={''}
              heading={''}
              subheading={''}
            />
          </div>
        </div>
      )}
    </section>
  );
}

const linkStyle = {
  textDecoration: 'none',
  color: 'black',
  transition: 'background-color 0.3s',
};

// Function to define the list item style based on hover state
const listItemStyle = (isHovered) => ({
  // backgroundColor: isHovered ? '#FFA500' : 'transparent',
  transition: 'background-color 0.3s',
  cursor: 'pointer',
  display: 'flex', // Make the li a flex container
  alignItems: 'center', // Vertically center align items
  borderRadius: '10px',

  transition: 'font-size 0.3s', // Add a transition for smooth effect
  fontSize: isHovered ? '1.2em' : '1em',
  fontWeight: isHovered ? 'bold' : 'normal',
});

export default SolutionSmartEU;
