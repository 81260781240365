import { useState, useRef } from 'react';

import { motion as m, useInView } from 'framer-motion';

//** importing the component */
import Typography from '@src/components/shared/Typography';

//**importing images */
import Image from '@shared/Image';
import dashboard from '@assets/svg/home/mprosmart3.jpg';
import dashboard1 from '@assets/svg/home/mprosmartshots2.png';
import {
  bottom_top_variants,
  top_bottom_variants,
} from '@src/utils/animations';
import OurServices from '@src/components/OurServices';
import msgThumpSubSvg from '@assets/svg/home/msgThumpSub.svg';
import mobileShopSvg from '@assets/svg/home/mobileShop.svg';
import socialMsgSvg from '@assets/svg/home/socialMsg.svg';
import startGiftSvg from '@assets/svg/home/startGift.svg';
import globalNetworkSvg from '@assets/svg/home/globalNetwork.svg';
import customerSupportSvg from '@assets/svg/home/customerSupport.svg';
import ProductHighlights from '../Home/ProductHighlights';
import Costumers from '../Home/Costumers';
import Testimonials from '@src/components/Testimonials';
import SolutionMProSmart from './SolutionMProSmart';

import bulbImg from '@assets/Images/bg/e4.jpg';
import bulbImg1 from '@assets/Images/bg/banner smart.jpg';
import { left_right_variants } from '@src/utils/animations';




const servicesData = [
  {
    img: msgThumpSubSvg,
    title: 'Project Scheduling and Monitoring',
    description:
      'The core application allows Oranizations to define Project Scope, WBS and Timelines. Planned Vs actuals can also be tracked realtime',
  },
  {
    img: mobileShopSvg,
    title: 'Budget & Expense Management',
    description:
      'Budgets can be allocated against every WBS / Activity and at the same time Expenses can also be recorded for the same.',
  },
  {
    img: socialMsgSvg,
    title: 'Site Supply Chain Management',
    description:
      'Organizations can leverage the Sourcing & WMS modules for managing the Site supply chain. Users can view the Inventory, Receipts and Issues for various projects',
  },
  {
    img: startGiftSvg,
    title: 'Asset Management.',
    description:
      'All Moveable and Non Moveable Assets can be defined and their Location / Usage can be easily tracked',
  },
  {
    img: globalNetworkSvg,
    title: 'Project Site Delivery',
    description:
      'Project Tracking and Delivery documentation can be easlity generated.',
  },
  {
    img: customerSupportSvg,
    title: 'Quality Control',
    description:
      'Quality standards and checklists for various stages of project development and supply chain can be defined and referred for Quality reports generation',
  },
];
const BenifitsData = [
  {
    img: msgThumpSubSvg,
    title: 'Decrease Risk of Costs and Overruns by 5-10 %',
    description:
      '',
  },
  {
    img: mobileShopSvg,
    title: 'Efficient Collaboration between Stakeholders',
    description:
      '',
  },
  {
    img: socialMsgSvg,
    title: 'Improved Visibility and Project Tracking',
    description:
      '',
  },
  {
    img: startGiftSvg,
    title: 'Improves Productivity of Site Managers',
    description:
      '',
  },
  {
    img: globalNetworkSvg,
    title: 'Consolidated Projects Views',
    description:
      '',
  },
  {
    img: customerSupportSvg,
    title: 'Improved Decision-Making',
    description:
      '',
  },
];

function MProSmart() {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [isactive, setIsActive] = useState(0);

  const links = [
    'Web Application',
    'Mobile Applications',
  ];

  return (
     <div className="">

      <section ref={ref} className="sm:pt-0">
            {/* <img src={bulbImg1} width='100%' className='w-[100rem] h-[36rem]' /> */}
            <div className="relative pt-40 pb-12 bg-primary-white xl:pt-48 sm:pb-16 lg:pb-32 xl:pb-48 2xl:pb-56">
      <div className="absolute inset-0 w-full h-100%">
        <m.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, ease: 'easeIn' }}
          className="object-fit:cover w-full h-full scale-x"
          src={bulbImg1}
          alt="hero img"
        />
      </div>

      <div className=" w-full h-full top-0 left-0 absolute backdropGradient"></div>
      <div className="relative">
        <div className="px-6 mx-auto sm:px-8 lg:px-12 max-w-7xl">
          <div className="flex flex-col w-full lg:w-[65%] xl:w-1/1 overflow-hidden">
            {/* <Typography
              mAnimate={{
                mVariants: left_right_variants,
                isInView: null,
                mDelay: 0.4,
              }}
              variant="P_Bold_Strong"
              className="text-white max-w-3xl"
              maxLines={2}
            > */}
            <m.h1
              initial="initial"
              animate="slidein"
              custom={0.4}
              variants={left_right_variants}
              className=" text-7xl font-bold text-white "
            >
              <span className=" !font-extrabold"> mProSMART </span> 

            </m.h1>
            {/* </Typography> */}
            <Typography
              mAnimate={{
                mVariants: left_right_variants,
                isInView: null,
                mDelay: 0.5,
              }}
              variant="P_Regular_H5"
              className="text-white mt-4 max-w-xl"
            >
              Managing Assets and Operations...
            </Typography>
            <m.div
              initial="initial"
              animate="slidein"
              custom={0.7}
              variants={left_right_variants}
              className="flex items-center mt-6 space-x-3 sm:space-x-4"
            >
              {/* <Button
                className=" !bg-white !text-primary-blue "
                shape={'round'}
              >
                Get Started
              </Button> */}
            </m.div>
          </div>
        </div>
      </div>
    </div>

        {/* <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 mb-14">
          <div className="max-w-2xl mx-auto text-center">
            <div className=" overflow-hidden">
              <Typography
                mAnimate={{
                  mVariants: bottom_top_variants,
                  isInView: isInView,
                  mDelay: 0,
                }}
                variant="P_Bold_H1"
                className="mt-5 !text-5xl block leading-tight text-mid-black sm:leading-tight lg:leading-tight"
              >
                mProSmart
              </Typography>
            </div>
            <div className=" overflow-hidden">
              <Typography
                mAnimate={{
                  mVariants: bottom_top_variants,
                  isInView: isInView,
                  mDelay: 0.1,
                }}
                variant="P_Medium_H5"
                className="text-mid-black mx-16  flex justify-center py-2 mt-2"
              >
                Infrastructure Industry Globally has registered great progress in the recent years. Future growth is anticipated and expected in several areas and there has been huge proposed investments in the next five years. In order to turn the infrastructure dream into a reality, there are great challenges which need to be tackled efficiently
              </Typography>
            </div>
          </div>
        </div> */}
        <OurServices
        servicesData={[]}
        title={'mProSmart'}
        description={
          'Infrastructure Industry Globally has registered great progress in the recent years. Future growth is anticipated and expected in several areas and there has been huge proposed investments in the next five years. In order to turn the infrastructure dream into a reality, there are great challenges which need to be tackled efficiently'
        }
      />

        <div className="flex justify-center flex-wrap  text-mid-black cursor-pointer md:overflow-hidden overflow-x-auto mb-5">
          {links.map((link, i) => (
            <m.div
              initial="initial"
              animate={isInView && 'visible'}
              variants={top_bottom_variants}
              custom={i * 0.07 + 0.2}
              viewport={{ once: true }}
              onClick={() => setIsActive(i)}
              className={`px-4 py-2 mx-6 ${
                isactive === i
                  ? ' border-b-[1px] border-b-primary-blue text-primary-blue'
                  : ''
              }`}
            >
              {link}
            </m.div>
          ))}
        </div>

        <div className="pb-16 bg-primary-white">
          <div className="relative w-full pb-1">
            <div className="relative mx-auto">
              <div className="lg:max-w-6xl lg:mx-auto overflow-hidden">
                <m.div
                  initial="initial"
                  animate={isInView && 'visible'}
                  variants={bottom_top_variants}
                  custom={0.6}
                  viewport={{ once: true }}
                  className=""
                >
                  <Image
                    link="/"
                    className="transform-scale-110"
                    src={isactive % 2 === 0 ? dashboard : dashboard1}
                    alt=""
                  />
                </m.div>
              </div>
            </div>
          </div>
        </div>
        <SolutionMProSmart />
        {/* <OurServices
        servicesData={[]}
        title={'Solution'}
        description={
          'SmartEU is an Integrated Enterprise solution comprising of Web Based Application, Mobility Solutions and Smart Devices for various stakeholders that is useful for Utility Companies, Consumers and Outsourced Agencies. Apart from the inbuilt workflows, SmartEU offers inbuilt Dashboards and Reporting Framework and helps users to generate/export Reports & analyze Data'
        }
      /> */}
        <OurServices
        servicesData={servicesData}
        title={'Product Highlights'}
        description={
          'mProSMART is designed for managing Capital Projects and its site operations remotely. Application is available on Web and Mobile app and users can access data in Online and offline mode reducing the dependency on Network availability. mProSMART application allows users to Define Project Scope, WBS, Schedule and Budgets initially and then a regular review and tracking. This helps them to identify the deviations in both Schedule and cost. mProSMART also helps project team to Manage Procurement, Quality Control and Inventory Management on the Site.'
        }
      />
      <OurServices
          servicesData={BenifitsData}
          title={'Business Benefits'}
          description={
            'mProSMART helps Organizations in Managing their Capital Projects efficiently with Minimal Delays and Least deviations from Budgets'
          }
        />
      {/* <Costumers />  */}
      {/* <Testimonials /> */}
      </section>
    // </div>
  );
}
export default MProSmart;
