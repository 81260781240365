import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
// import pdfjs from 'pdfjs-dist/build/pdf.min';

import logo from '@assets/Images/logo/adbhar_logo_text.png';
import { yupResolver } from "@hookform/resolvers/yup";
import { contactUsSchema } from "../../utils/validation";
// ** import shared components
import InputText from '@shared/InputText';
import Button from '../shared/Button';
import PhoneInput from 'react-phone-input-2';
import "react-phone-input-2/lib/style.css";
import axios from '../../axios'
import TextArea from '@shared/TextArea';



const ContactForm = ({ setOpen }) => {

  const [mobile, setMobile] = useState('')
  const [errtatus, setErrtatus] = useState(false)
  const [loading, setLoading] = useState(false)
  const [description, setDescription] = useState('')



  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(contactUsSchema),
  });




  const handelForm = async (res) => {
    if (mobile.length === 0) {
      return setErrtatus(true)
    }
    setLoading(true)
    const { data } = await axios.post('/contact', {
      name: `${res.FirstName} ${res.LastName}`,
      email: res.Email,
      mobile: mobile,
      message: description

    })
    if (data.success) {
      toast.success(`${data.message}`)
      reset({
        FirstName: "",
        LastName: "",
        Email: "",
      })
      setMobile("")
      setLoading(false)
      setOpen(false)
    } else {
      setLoading(false)
    }
  };

  return (
    <>
      <div className="flex justify-center  w-full items-center overflow-x-hidden bg-[#00000052]  fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex items-center gap-4 justify-center p-6 bg-white rounded-3xl w-[1000px] max-w-[90%] text-black relative">
          <div className=" hidden flex-1 h-full lg:grid place-items-center">
            <img
              src={logo}
              alt="contact-us image"
              className=" w-1/2 h-full object-cover"
            />
          </div>
          <svg
            onClick={() => setOpen(false)}
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            className="absolute right-2 top-2  cursor-pointer"
          >
            <g clip-path="url(#clip0_464_59069)">
              <path
                d="M20 0C9.01484 0 0 9.01484 0 20C0 30.9852 9.01484 40 20 40C30.9852 40 40 30.9852 40 20C40 9.01484 30.9852 0 20 0ZM29.9438 26.6295L26.6295 29.9438L20 23.3142L13.3705 29.9438L10.0562 26.6295L16.6858 20L10.0562 13.3705L13.3705 10.0562L20 16.6858L26.6295 10.0562L29.9438 13.3705L23.3142 20L29.9438 26.6295Z"
                fill="#0176D3"
              />
            </g>
            <defs>
              <clipPath id="clip0_464_59069">
                <rect width="40" height="40" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <div className="flex-1 p-4">
            <h1 className=" text-4xl xl:text-5xl text-center font-extrabold !font-poppins text-black">
              Contact Us
            </h1>


            <form onSubmit={handleSubmit(handelForm)} className="mt-6 space-y-6">
              <div className="flex gap-4 w-full md:flex-row flex-col ">
                <div className="flex-1">
                  <InputText
                    name="FirstName"
                    type="text"
                    placeholder="firstName"
                    register={register}
                    error={errors?.FirstName?.message}
                  />
                </div>
                <div className="flex-1">
                  <InputText
                    name="LastName"
                    type="text"
                    placeholder="lastName"
                    register={register}
                    error={errors?.LastName?.message}
                  />
                </div>
              </div>
              <div className="mt-4">
                <InputText
                  name="Organization"
                  type="text"
                  placeholder="organization"
                  register={register}
                  error={errors?.Organization?.message}
                />
              </div>
              <div className="mt-4">
                <InputText
                  name="Email"
                  type="email"
                  placeholder="email"
                  register={register}
                  error={errors?.Email?.message}
                />
              </div>
              <div className="mt-4">
                <label htmlFor="phone-input">Phone Number</label>
                <PhoneInput
                  placeholder="Phone number"
                  country={"in"}
                  value={mobile}
                  className=" customInputSize block w-full bg-white  text-base text-[#61748E] border border-gray-200 rounded-xl focus:outline-none focus-within:border-primary focus-within:ring-1 focus:ring-primary"
                  onChange={setMobile}
                  name="phone"
                  defaultCountry={"in"}

                />
                {errtatus && mobile === undefined && (
                  <p className="error-message text-danger ">Invalid Phone</p>
                )}
                {errtatus && mobile === '' && (
                  <p className="error-message text-danger ">Invalid Phone</p>
                )}
                {errtatus && mobile?.length > 0 && mobile?.length < 10 && (
                  <p className="error-message text-danger ">Enter correct mobile number</p>
                )}
              </div>
              <div className="mt-6">
                <TextArea
                  label="Description"
                  name="message"
                  placeholder="Write us your question here..."
                  register={register}
                  required={true}
                  error={errors.message}
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}


                />
              </div>
              {
                loading ? <Button
                  type="submit"
                  className=" mt-6 inline-flex items-center justify-center w-full px-5 py-3 text-base font-medium text-primary-white transition-all duration-200 bg-primary-blue border border-transparent rounded-xl hover:bg-primary-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-blue"
                >
                  Loading...
                </Button> : <Button
                  type="submit"
                  className=" mt-6 inline-flex items-center justify-center w-full px-5 py-3 text-base font-medium text-primary-white transition-all duration-200 bg-primary-blue border border-transparent rounded-xl hover:bg-primary-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-blue"
                >
                  Send
                </Button>
              }

            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
