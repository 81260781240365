import { useState, useRef } from 'react';

import { motion as m, useInView } from 'framer-motion';

//** importing the component */
import Typography from '@src/components/shared/Typography';
import Hero from '@src/components/Hero'

//**importing images */
import Image from '@shared/Image';
import dashboard from '@assets/svg/home/Graph.png';
import dashboard1 from '@assets/svg/home/smarteushots.png';

import {
  bottom_top_variants,
  top_bottom_variants,
} from '@src/utils/animations';
import Solution from './SolutionSmartEU';
import OurServices from '@src/components/OurServices';
import msgThumpSubSvg from '@assets/svg/home/msgThumpSub.svg';
import mobileShopSvg from '@assets/svg/home/mobileShop.svg';
import socialMsgSvg from '@assets/svg/home/socialMsg.svg';
import startGiftSvg from '@assets/svg/home/startGift.svg';
import globalNetworkSvg from '@assets/svg/home/globalNetwork.svg';
import customerSupportSvg from '@assets/svg/home/customerSupport.svg';
import ProductHighlights from '../Home/ProductHighlights';
import Costumers from '../Home/Costumers';
import Testimonials from '@src/components/Testimonials';
import SolutionSmartEU from './SolutionSmartEU';

import bulbImg from '@assets/Images/bg/e2.jpg';
import { left_right_variants } from '@src/utils/animations';





const servicesData = [
  {
    img: msgThumpSubSvg,
    title: 'Cloud Based Enterprise Application',
    description:
      '',
  },
  {
    img: mobileShopSvg,
    title: 'Automated Business Process & Workflows',
    description:
      '',
  },
  {
    img: socialMsgSvg,
    title: 'Scalable Solutions & Faster Implementation',
    description:
      '',
  },
  {
    img: startGiftSvg,
    title: 'Pre-built Integration Framework',
    description:
      '',
  },
  {
    img: globalNetworkSvg,
    title: 'Available on Web and Mobile Platforms',
    description:
      '',
  },
  {
    img: customerSupportSvg,
    title: 'Pre-built Dashboards and Reporting Framework',
    description:
      '',
  },
];
const BenifitsData = [
  {
    img: msgThumpSubSvg,
    title: 'Reduced Billing & Collection Losses',
    description:
      '',
  },
  {
    img: mobileShopSvg,
    title: 'Improved Business & Field-Users Productivity',
    description:
      '',
  },
  {
    img: socialMsgSvg,
    title: 'Improved Customer Engagement',
    description:
      '',
  },
  {
    img: startGiftSvg,
    title: 'Effective Decision Making',
    description:
      '',
  },
  {
    img: globalNetworkSvg,
    title: '100% Transparency',
    description:
      '',
  },
  {
    img: customerSupportSvg,
    title: 'Streamlined & Optimised Operations',
    description:
      '',
  },
];

function SmartEU() {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [isactive, setIsActive] = useState(0);

  const links = [
    'Web Application',
    'Mobile Application',
  ];

  return (
    
    // <div className="overflow-x-hidden ">

       
      <section ref={ref} className="sm:pt-0">
      {/* <img src={bulbImg} width='100%' className='w-[100rem] h-[36rem]'/>
       */}
          <div className="relative pt-40 pb-12 bg-primary-white xl:pt-48 sm:pb-16 lg:pb-32 xl:pb-48 2xl:pb-56">
      <div className="absolute inset-0 w-full h-100%">
        <m.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, ease: 'easeIn' }}
          className="object-fit:cover w-full h-full scale-x"
          src={bulbImg}
          alt="hero img"
        />
      </div>

      <div className=" w-full h-full top-0 left-0 absolute backdropGradient"></div>
      <div className="relative">
        <div className="px-6 mx-auto sm:px-8 lg:px-12 max-w-7xl">
          <div className="flex flex-col w-full lg:w-[65%] xl:w-1/1 overflow-hidden">
            {/* <Typography
              mAnimate={{
                mVariants: left_right_variants,
                isInView: null,
                mDelay: 0.4,
              }}
              variant="P_Bold_Strong"
              className="text-white max-w-3xl"
              maxLines={2}
            > */}
            <m.h1
              initial="initial"
              animate="slidein"
              custom={0.4}
              variants={left_right_variants}
              className=" text-7xl font-bold text-white "
            >
              <span className=" !font-extrabold"> SmartEU </span> 

            </m.h1>
            {/* </Typography> */}
            <Typography
              mAnimate={{
                mVariants: left_right_variants,
                isInView: null,
                mDelay: 0.5,
              }}
              variant="P_Regular_H5"
              className="text-white mt-4 max-w-xl"
            >
              Digitizing Utilities...
            </Typography>
            <m.div
              initial="initial"
              animate="slidein"
              custom={0.7}
              variants={left_right_variants}
              className="flex items-center mt-6 space-x-3 sm:space-x-4"
            >
              {/* <Button
                className=" !bg-white !text-primary-blue "
                shape={'round'}
              >
                Get Started
              </Button> */}
            </m.div>
          </div>
        </div>
      </div>
    </div>

        {/* <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 mb-14">
          <div className="max-w-2xl mx-auto text-center">
            <div className=" overflow-hidden">
              <Typography
                mAnimate={{
                  mVariants: bottom_top_variants,
                  isInView: isInView,
                  mDelay: 0,
                }}
                variant="P_Bold_H1"
                className="mt-5 !text-5xl block leading-tight text-mid-black sm:leading-tight lg:leading-tight"
              >
                SmartEU
              </Typography>
            </div>
            <div className=" overflow-hidden">
              <Typography
                mAnimate={{
                  mVariants: bottom_top_variants,
                  isInView: isInView,
                  mDelay: 0.1,
                }}
                variant="P_Medium_H5"
                className=" text-dark-white mt-4"
              >
                ABHARTECH has made big investment in Solution Development for Electricity, Water & Gas Utilities. The vision is to completely Digitize Utilities and provide its benefits to our utility customers.
                ABHARTECH has dedicated Center of Excellence building innovative solutions for its customers. We closely monitor changing regulations, customer needs, technology advances and accordingly come up with new ideas and accelerators. This helps us to stay ahead in the industry and provide latest solutions to problems which Utilities face, even before they realize that this becomes a necessity. Our solutions are flexible and adaptable in nature and can be easily enhanced and upgraded.
              </Typography>
            </div>
          </div>
        </div> */}

        <OurServices
        servicesData={[]}
        title={'SmartEU'}
        description={
          ' ABHARTECH has made big investment in Solution Development for Electricity, Water & Gas Utilities. The vision is to completely Digitize Utilities and provide its benefits to our utility customers. ABHARTECH has dedicated Center of Excellence building innovative solutions for its customers. At ABHARTECH, We closely monitor changing regulations, customer needs, technology advances and accordingly come up with new ideas and accelerators. This helps us to stay ahead in the industry and provide latest solutions to problems which Utilities face, even before they realize that this becomes a necessity. Our solutions are flexible and adaptable in nature and can be easily enhanced and upgraded.'
        }
      />

        <div className="flex justify-center flex-wrap  text-mid-black cursor-pointer md:overflow-hidden overflow-x-auto mb-5">
          {links.map((link, i) => (
            <m.div
              initial="initial"
              animate={isInView && 'visible'}
              variants={top_bottom_variants}
              custom={i * 0.07 + 0.2}
              viewport={{ once: true }}
              onClick={() => setIsActive(i)}
              className={`px-4 py-2 mx-6 ${
                isactive === i
                  ? ' border-b-[1px] border-b-primary-blue text-primary-blue'
                  : ''
              }`}
            >
              {link}
            </m.div>
          ))}
        </div>

        <div className="pb-16 bg-primary-white">
          <div className="relative w-full">
            <div className="relative mx-auto">
              <div className="lg:max-w-6xl lg:mx-auto overflow-hidden">
                <m.div
                  initial="initial"
                  animate={isInView && 'visible'}
                  variants={bottom_top_variants}
                  custom={0.6}
                  viewport={{ once: true }}
                  className=""
                >
                  <Image
                    link="/"
                    className="transform-scale-110"
                    src={isactive % 2 === 0 ? dashboard : dashboard1}
                    alt=""
                  />
                </m.div>
              </div>
            </div>
          </div>
        </div>
        <SolutionSmartEU />
        {/* <OurServices
        servicesData={[]}
        title={'Solution'}
        description={
          'SmartEU is an IIntegrated Enterprise solution comprising of Web Based Application, Mobility Solutions and Smart Devices for various stakeholders that is useful for Utility Companies, Consumers and Outsourced Agencies. Apart from the inbuilt workflows, SmartEU offers inbuilt Dashboards and Reporting Framework and helps users to generate/export Reports & analyze Data'
        }
      /> */}
        <OurServices
        servicesData={servicesData}
        title={'Product Highlights'}
        description={
          'ABHARTECH’s SmartEU solution is an exclusive-fit for Smart Cities initiative. Offers digitized and automated processes to Utilities and helps in providing best-in-class Services to its customers and minimum Downtime possible '
        }
      />
      <OurServices
          servicesData={BenifitsData}
          title={'Business Benefits'}
          description={
            'SmartEU helps Utilities in Managing Business Operations, People & Assets effectively and provide complete transparency to all the stakeholders'
          }
        />
      {/* <Costumers />  */}
      {/* <Testimonials /> */}
      </section>
    // </div>
  );
}
export default SmartEU;
