import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import emailjs from '@emailjs/browser';
import { toast } from 'react-hot-toast';
// import pdfjs from 'pdfjs-dist/build/pdf.min';

import logo from '@assets/Images/logo/adbhar_logo_text.png';
// ** import shared components
import TextInput from '@shared/TextInput';
import EmailInput from '@shared/EmailInput';
import Button from '../shared/Button';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import axios from '../../axios';
import OtpInput from 'react-otp-input';

// ** import env
import { env } from '@config/index';

const BronchureForm = ({ setOpen, attachmentsIndex }) => {
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    organization: '',
    mobile:"",
    attachmentsIndex:attachmentsIndex,
  });
  const [mobile, setMobile] = useState('');
  const [errtatus, setErrtatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const [otp, setOtp] = useState('');
  const [step, setStep] = useState(1);
  const [seconds, setSeconds] = useState(600);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const form = useRef();

  const onSubmit = async (data) => {
    const { fullName, lastName, email } = data;
    try {
      await emailjs.send(env.SERVICE_ID, env.TEMPLATE_ID, {
        to_name: `${fullName} ${lastName}`,
        to_email: email,
      });
    } catch (error) {
      console.error(error);
      const errorMessage = error.message || 'An error occurred.';
      toast.error(errorMessage);
    }
  };

  const handelchange = (e) => {
    const { name, value } = e.target;

    setUserInfo((pre) => {
      return {
        ...pre,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (seconds > 0) {
      const countdown = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return async () => clearInterval(countdown);
    } else {
      const API = async () => {
        await axios.post('/remove-otp', {
          email: userInfo.email,
        });
      };
      API();
    }
  }, [seconds]);

  const handlesubmit = async () => {
    const Name = `${userInfo.firstName} ${userInfo.lastName}`;
    const Email = userInfo.email;
    const Mobile = userInfo.mobile;
    const Organization = userInfo.organization;

    if (Mobile === undefined || Mobile === '' || Mobile.length < 10) {
      setErrtatus(true);
    }
    setLoading1(true);
    const { data } = await axios.post('/verify-otp', {
      email: userInfo.email,
      otp: Number(otp),
    });

    if (data.success) {
      const datas = await axios.post('/intimateCompany', {
        email: Email,
        name: Name,
        mobile: Mobile,
        organization: Organization,
        attachmentsIndex:attachmentsIndex,
      });
      if (datas.data.success) {
        toast.success('Successfully sended');
        setErrtatus(false);
        setLoading1(false);
        setOpen(false);
        return;
      } else {
        setLoading(false);
        return toast.error(`${datas.data.message}`);
      }
    } else {
      setLoading1(false);
      return toast.error('Invalid OTP');
    }
  };

  const sendOTP = async () => {
    const Name = `${userInfo.firstName} ${userInfo.lastName}`;
    setLoading(true);
    const { data } = await axios.post('/otp-send', {
      email: userInfo.email,
      name: Name,
    });
    if (data.success) {
      toast.success(`${data.message}`);
      setLoading(false);
      return setStep(2);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const resendOTP = async () => {
    if(seconds === 0) {
      const Name = `${userInfo.firstName} ${userInfo.lastName}`;
      const { data } = await axios.post('/resend-otp', {
        email: userInfo.email,
        name: Name,
      });
      if (data.success) {
        toast.success(`${data.message}`);
        setSeconds(600);
        return;
      }
    } else {
      return toast.error('OTP Expire time is running')
    }
  };

  return (
    <>
      <div className="flex justify-center  w-full items-center overflow-x-hidden bg-[#00000052]  fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex items-center gap-4 justify-center p-6 bg-white rounded-3xl w-[1000px] max-w-[90%] text-black relative">
          <div className=" hidden flex-1 h-full lg:grid place-items-center">
            <img
              src={logo}
              alt="contact-us image"
              className=" w-1/2 h-full object-cover"
            />
          </div>
          <svg
            onClick={() => setOpen(false)}
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            className="absolute right-2 top-2  cursor-pointer"
          >
            <g clip-path="url(#clip0_464_59069)">
              <path
                d="M20 0C9.01484 0 0 9.01484 0 20C0 30.9852 9.01484 40 20 40C30.9852 40 40 30.9852 40 20C40 9.01484 30.9852 0 20 0ZM29.9438 26.6295L26.6295 29.9438L20 23.3142L13.3705 29.9438L10.0562 26.6295L16.6858 20L10.0562 13.3705L13.3705 10.0562L20 16.6858L26.6295 10.0562L29.9438 13.3705L23.3142 20L29.9438 26.6295Z"
                fill="#0176D3"
              />
            </g>
            <defs>
              <clipPath id="clip0_464_59069">
                <rect width="40" height="40" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <div className="flex-1 p-4 min-h-[600px]">
            <h1 className=" text-4xl xl:text-3xl text-center font-extrabold !font-poppins text-black">
              Download Brochure
            </h1>
            {/* <h4 className=" text-lg font-md text-gray-400 mt-2">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            </h4> */}

            {step === 1 && (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(onSubmit);
                  sendOTP();
                }}
                className=" mt-8"
                ref={form}
              >
                <div className="flex gap-4 w-full md:flex-row flex-col ">
                  <div className="flex-1">
                    <TextInput
                      label="First name"
                      name="firsName"
                      register={register}
                      required
                      error={errors.fullName}
                      onChange={handelchange}
                    />
                  </div>
                  <div className="flex-1">
                    <TextInput
                      label="Last name"
                      name="lastName"
                      register={register}
                      required
                      error={errors.lastName}
                      onChange={handelchange}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <EmailInput
                    label="Email"
                    name="email"
                    register={register}
                    required
                    error={errors.email}
                    onChange={handelchange}
                  />
                </div>
                <div className="mt-4">
                  <TextInput
                    label="Organization"
                    name="organization"
                    register={register}
                    required
                    error={errors.organization}
                    onChange={handelchange}
                  />
                </div>
                <div className="mt-4">
                  {/* <label htmlFor="phone-input">Phone Number</label> */}
                  {/* <PhoneInput
                    placeholder="Phone number"
                    country={'in'}
                    value={mobile}
                    className=" customInputSize block w-full bg-white  text-base text-[#61748E] border border-gray-200 rounded-xl focus:outline-none focus-within:border-primary focus-within:ring-1 focus:ring-primary"
                    onChange={handleMobileChange}
                    name="phone"
                    defaultCountry={'in'}
                  /> */}
                  <TextInput
                    label="Phone Number"
                    name="mobile"
                    register={register}
                    required
                    // pattern
                    error={errors.mobile}
                    onChange={handelchange}
                  />
                  {errtatus && (
                    <p className="error-message text-danger ">Invalid Phone</p>
                  )}
                  {errtatus && (
                    <p className="error-message text-danger ">Invalid Phone</p>
                  )}
                  {errtatus && mobile?.length > 0 && mobile?.length < 10 && (
                    <p className="error-message text-danger ">
                      Enter correct mobile number
                    </p>
                  )}
                </div>
                {loading ? (
                  <Button
                    type="submit"
                    className=" mt-6 inline-flex items-center justify-center w-full px-5 py-3 text-base font-medium text-primary-white transition-all duration-200 bg-primary-blue border border-transparent rounded-xl hover:bg-primary-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-blue"
                  >
                    Loading...
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    className=" mt-6 inline-flex items-center justify-center w-full px-5 py-3 text-base font-medium text-primary-white transition-all duration-200 bg-primary-blue border border-transparent rounded-xl hover:bg-primary-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-blue"
                  >
                    Download
                  </Button>
                )}
              </form>
            )}
            {step === 2 && (
              <div className="flex items-center justify-center min-h-[500px] flex-col">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                  inputStyle={'otpInput'}
                />
                <p
                  onClick={resendOTP}
                  className="mt-3 text-blue-500 cursor-pointer underline"
                >
                  {' '}
                  Resend code ({formatTime(seconds)})
                </p>

                {loading1 ? (
                  <Button className=" mt-16 inline-flex items-center justify-center w-full px-5 py-3 text-base font-medium text-primary-white transition-all duration-200 bg-primary-blue border border-transparent rounded-xl hover:bg-primary-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-blue">
                    Loading...
                  </Button>
                ) : (
                  <Button
                    onClick={handlesubmit}
                    className=" mt-16 inline-flex items-center justify-center w-full px-5 py-3 text-base font-medium text-primary-white transition-all duration-200 bg-primary-blue border border-transparent rounded-xl hover:bg-primary-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-blue"
                  >
                    Submit OTP
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BronchureForm;
