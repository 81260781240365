import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { motion as m, useInView } from 'framer-motion';
// ** import components
import Card from '@src/components/shared/Card';

// ** import icons
import { LinkOutIcon, LocationIcon, SuitcaseIcon } from '@src/assets/svg/icons';
import Typography from '@src/components/shared/Typography';
import SelectInput from '@src/components/shared/SelectInput';
import { top_bottom_variants } from '@src/utils/animations';

function JobInfo() {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      const url = process.env.REACT_APP_FETCHING_API_TESTING;
      console.log(url + `/getCareers`, 'URL');
      try {
        const response = await axios.post(
          url + `/getCareers`,
          // 'http://localhost:5000/api/getCareers',
        );
        setJobs(response.data.data);
      } catch (error) {
        console.error('Error fetching job data:', error);
      }
    };

    fetchJobs();
  }, []);

  const renderJobCard = (job) => {
    const cardHeader = (
      <div className="flex justify-between w-full ">
        <div className="flex items-center max-w-[80%] sm:max-w-[70%] h-20">
          <Typography variant="P_SemiBold_H4" maxLines={2}>
            {job.job_name}
          </Typography>
        </div>
        <div className="flex items-center mt-2 sm:mt-0">
          <Link to={`${job.id}`}>
            <div className="flex items-center gap-2">
              <p className="hidden sm:block">More Details</p>
              <LinkOutIcon size={16} />
            </div>
          </Link>
        </div>
      </div>
    );

    const cardFooter = (
      <div className="flex flex-col sm:flex-row justify-start sm:justify-between items-start gap-2">
        <div className="flex items-center">
          <SuitcaseIcon size={16} />
          <span className="ml-2">{job.job_role}</span>
        </div>
        <div className="flex items-center mt-2 sm:mt-0">
          <LocationIcon size={16} />
          <span className="ml-2">{job.location}</span>
        </div>
      </div>
    );

    return (
      <Card
        clickable
        key={job.id}
        className="bg-light-blue !w-10/12 !mb-10 border-0 my-5"
        header={cardHeader}
        footer={cardFooter}
      >
        <p className="mb-2 text-2xl">{job.experience}</p>
        <p className="mt-2">{job.qualification}</p>
        <p className="mt-2">{job.job_description}</p>
      </Card>
    );
  };

  return (
    <div ref={ref} className="bg-white-50">
      <div className="flex flex-col items-center px-4 gap-4 lg:px-[16rem] pb-14 mx-auto justify-center md:justify-between md:flex-row pt:0 mt-8">
        <m.div
          initial="initial"
          animate={isInView && 'visible'}
          variants={top_bottom_variants}
          custom={0 + 0.2}
          viewport={{ once: true }}
          className="flex-[1] w-full  mb-3 md:mb-0"
        >
          <SelectInput
            className="w-full"
            options={[
              { name: 'All Job Category' },
              { name: 'Engineering' },
              { name: 'Software' },
            ]}
          />
        </m.div>
        <m.div
          initial="initial"
          animate={isInView && 'visible'}
          variants={top_bottom_variants}
          custom={0.1 + 0.2}
          viewport={{ once: true }}
          className="flex-[1] w-full mb-3 md:mb-0 "
        >
          <SelectInput
            className="w-full"
            options={[
              { name: 'All Job Type' },
              { name: 'Engineer' },
              { name: 'Developer' },
            ]}
          />
        </m.div>
        <m.div
          initial="initial"
          animate={isInView && 'visible'}
          variants={top_bottom_variants}
          custom={0.3 + 0.2}
          viewport={{ once: true }}
          className="flex-[1] w-full mb-3 md:mb-0 "
        >
          <SelectInput
            className="w-full"
            options={[
              { name: 'All Job Location' },
              { name: 'On-site' },
              { name: 'Hybrid' },
              { name: 'Remote' },
            ]}
          />
        </m.div>
      </div>
      <div className="px-0 sm:px-[2rem] md:px-[4rem] lg:px-[16rem] z-10">
        <div className="">{jobs.map((job) => renderJobCard(job))}</div>
      </div>
    </div>
  );
}

export default JobInfo;
