import React from 'react';
import PropTypes from 'prop-types';

function TextInput({ label, name, register, required, error, onChange, value }) {
  return (
    <div>
      <label htmlFor={name} className="text-base font-medium text-gray-900">
        {label}
      </label>
      <div className="mt-2">
        <input
          {...register(name, { required })}
          type="text"
          id={name}
          value={value}
          onChange={onChange} // Updated here to use the 'onChange' prop
          placeholder={`Enter your ${label.toLowerCase()}`}
          required
          className="block w-full px-4 py-3 text-base text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none focus:border-blue-600 focus:ring-1 focus:ring-blue-600"
        />
        {error && <span className="text-red-500">This field is required</span>}
      </div>
    </div>
  );
}

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  required: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default TextInput;
