import React, { useRef } from 'react';

import { motion as m, useInView } from 'framer-motion';

// ** import icons
import { Icon } from '@iconify/react';

// ** import components
import Image from '@shared/Image';
import Typography from '@shared/Typography';
import Button from '@shared/Button';

// ** import images
import post1Img from '@assets/Images/home/posts/post1.png';
import post2Img from '@assets/Images/home/posts/post2.png';
import post3Img from '@assets/Images/home/posts/post3.png';
import post4Img from '@assets/Images/home/posts/post4.png';
import {
  bottom_top_variants,
  left_right_variants,
  top_bottom_variants,
} from '@src/utils/animations';

const postList = [
  {
    image: post1Img,
    title: 'Nonprofit Summit: Direct and Expand Your Programs Sessions',
  },
  {
    image: post2Img,
    title: 'Nonprofit Summit: Direct and Expand Your Programs Sessions',
  },
  {
    image: post3Img,
    title: 'Nonprofit Summit: Direct and Expand Your Programs Sessions',
  },
  {
    image: post4Img,
    title: 'Nonprofit Summit: Direct and Expand Your Programs Sessions',
  },
];

function ExploreBlog() {
  const ref = useRef(null);
  const isInView = useInView(ref);

  return (
    <section className="py-10 bg-light-blue">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 w-full">
        <div className="text-center pb-14 flex flex-col justify-center w-fit mx-auto overflow-hidden">
          <Typography
            mAnimate={{
              mVariants: bottom_top_variants,
              isInView: isInView,
              mDelay: 0,
            }}
            variant="P_SemiBold_H3"
            className="text-primary-black"
          >
            Explore Our Blog
          </Typography>
          <Typography
            mAnimate={{
              mVariants: bottom_top_variants,
              isInView: isInView,
              mDelay: 0.1,
            }}
            variant="P_Regular_H6"
            className="mt-2 text-dark-white"
          >
            Lorem Ipsum is simply dummy text
          </Typography>
        </div>
        <div className="flex flex-col lg:flex-row md:gap-y-12 gap-x-8 w-full">
          <div
            ref={ref}
            className="text-center lg:text-left max-lg:w-full flex flex-col w-1/2 overflow-hidden"
          >
            <m.div
              initial="initial"
              animate={isInView && 'slidein'}
              custom={0.3}
              variants={left_right_variants}
              className="bg-transparent overflow-hidden w-full lg:w-max lg:max-w-xl"
            >
              <div className="rounded-xl group overflow-hidden">
                <Image src={postList[0].image} />
              </div>
              <div className="text-left py-8 sm:py-10 flex flex-col ">
                <Typography
                  variant="P_SemiBold_H5"
                  className=" text-primary-black"
                  maxLines={3}
                >
                  {postList[0].title}
                </Typography>
                <Button
                  iconEnd={
                    <Icon icon="basil:arrow-right-solid" fontSize={32} />
                  }
                  className={'mt-4 text-primary-blue !px-0 max-w-fit'}
                  variant="plain"
                  position="start"
                >
                  Know More
                </Button>
              </div>
            </m.div>
          </div>

          <div className="flex w-1/2 flex-wrap max-lg:w-full ">
            <div className="flex flex-col gap-y-8 items-start overflow-hidden">
              {postList.slice(1, 4).map((item, idx) => (
                <div className=" overflow-hidden">
                  <m.div
                    key={idx}
                    initial="initial"
                    animate={isInView && 'visible'}
                    custom={0.4 + idx * 0.08}
                    variants={top_bottom_variants}
                    className="flex w-full"
                  >
                    <div className="flex group flex-col xs:flex-row lg:flex-nowrap  gap-5 w-full ">
                      <div className=" h-auto w-full lg:max-w-[272px]">
                        <div className="rounded-xl overflow-hidden">
                          <Image src={item.image} />
                        </div>
                      </div>
                      <div className="h-auto w-full flex flex-col flex-wrap lg:max-w-[332px]">
                        <Typography
                          variant="P_SemiBold_H5"
                          className="text-primary-black"
                          maxLines={3}
                        >
                          {item.title}
                        </Typography>
                        <Button
                          iconEnd={
                            <Icon
                              icon="basil:arrow-right-solid"
                              fontSize={32}
                            />
                          }
                          className={'mt-4 text-primary-blue !px-0 max-w-fit'}
                          variant="plain"
                          position="start"
                        >
                          Know More
                        </Button>
                      </div>
                    </div>
                  </m.div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ExploreBlog;
