import React from 'react';

// import images
import man from '@assets/Images/services/man.svg';
import man1 from '@assets/Images/services/man1.svg';
import ts from '@assets/Images/services/coding.svg';
import coding1 from '@assets/Images/services/coding1.svg';
import coding from '@assets/Images/services/ts.svg';
import ts1 from '@assets/Images/services/ts1.svg';
import { useState } from 'react';
import Typography from '@src/components/shared/Typography';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import Button from '@src/components/shared/Button';

import left from '@assets/Images/services/left.svg';
import left1 from '@assets/Images/services/left1.svg';
import left2 from '@assets/Images/services/left2.svg';
import service1 from '@assets/Images/services/service1.svg';
import service from '@assets/Images/services/service.svg';
import solar from '@assets/Images/bg/solar.png';


import { Link } from 'react-router-dom';

const Remote = () => {
  const [selected, setSelect] = useState(1);

  return (
    <div className={`${selected === 1 ? 'bg-primary-blue' : selected === 2 ? 'bg-primary-blue' : selected === 3 ? 'bg-primary-blue' :selected === 4 ? 'bg-primary-blue':'bg-light_red'}`}>
      <div className='max-w-7xl mx-auto'>
        <div className="w-full flex items-center overflow-hidden">
          <div
            onClick={() => setSelect(1)}
            className={`${selected === 1
              ? 'bg-primary-blue'
              : 'bg-white border-r-[1px] border-light_dark'
              } w-full flex justify-center items-center py-10 cursor-pointer transition-all duration-300`}
          >
            {selected === 1 ? (
              <img className="bg bg-b w-[65px] h-[65px]" src={man} alt="" />
            ) : (
              <img className="bg bg-b w-[65px] h-[65px]" src={man1} alt="" />
            )}
            <Typography
              variant="P_SemiBold_H5"
              className={`${selected === 1 && 'text-white'
                } ml-2 transition-all duration-300 ms:block hidden`}
            >
              Consulting <br /> Services
            </Typography>
          </div>

          <div
            onClick={() => setSelect(2)}
            className={`${selected === 2
              ? 'bg-light_green'
              : 'bg-white border-r-[1px] border-light_dark'
              } w-full flex justify-center items-center py-10 cursor-pointer transition-all duration-300`}
          >
            {selected === 2 ? (
              <img className="bg bg-b w-[65px] h-[65px]" src={ts} alt="" />
            ) : (
              <img className="bg bg-b w-[65px] h-[65px]" src={ts1} alt="" />
            )}
            <Typography
              variant="P_SemiBold_H5"
              className={`${selected === 2 && 'text-white'
                } ml-2 transition-all duration-300 ms:block hidden`}
            >
              Nextgen <br /> Services
            </Typography>
          </div>

          <div
            onClick={() => setSelect(4)}
            className={`${selected === 4 ? 'bg-light_red' : 'bg-white'
              } w-full flex justify-center items-center py-10 cursor-pointer transition-all duration-300`}
          >
            {selected === 4 ? (
              <img className="bg bg-b w-[65px] h-[65px]" src={coding} alt="" />
            ) : (
              <img className="bg bg-b w-[65px] h-[65px]" src={coding1} alt="" />
            )}
            <Typography
              variant="P_SemiBold_H5"
              className={`${selected === 4 && 'text-white'
                } ml-2 transition-all duration-300 ms:block hidden`}
            >
              Integration <br /> Services 
            </Typography>
          </div>
          <div
            onClick={() => setSelect(3)}
            className={`${selected === 3 ? 'bg-light_red' : 'bg-white'
              } w-full flex justify-center items-center py-10 cursor-pointer transition-all duration-300`}
          >
            {selected === 3 ? (
              <img className="bg bg-b w-[65px] h-[65px]" src={service} alt="" />
            ) : (
              <img className="bg bg-b w-[65px] h-[65px]" src={service1} alt="" />
            )}
            <Typography
              variant="P_SemiBold_H5"
              className={`${selected === 3 && 'text-white'
                } ml-2 transition-all duration-300 ms:block hidden`}
            >
              Solution Development <br /> Services
            </Typography>
          </div>
        </div>
        {selected === 1 && (
          <div className="bg-primary-blue py-16 px-10 sm:px-20 flex items-center justify-between md:flex-row flex-col">
            <div className="md:w-[45%] sm:w-[70%] w-[90%] md:hidden block">
              <img src={left} alt="" />
            </div>
            <div className="md:w-[50%] sm:w-[80%] w-[95%] md:mt-0 mt-10">
              <p className="text-white font-poppins font-normal text-[14px] md:text-[16px] tracking-normal">
                
              </p>
              <Typography variant="P_SemiBold_H3" className="text-white ">
                
              </Typography>

              <div className="my-5 flex flex-col gap-y-3">
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                    Strategy Consulting
                  </Typography>
                </div>
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                    Infrastructure Consulting
                  </Typography>
                </div>
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                    Business Consulting
                  </Typography>
                </div>
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                    Master Systems Integration
                  </Typography>
                </div>
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                    Packaged Implementation (Oracle & SAP)
                  </Typography>
                </div>
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                    Poduct Upgrades
                  </Typography>
                </div>
              </div>
              <Link to='/contact-us'>

              {/* <Button
                className={'!bg-white !text-primary-blue mt-5'}
                shape={'round'}
                >
                
              </Button> */}
                </Link>
            </div>
            <div className="w-[45%] md:block hidden">
              <img src={left} alt="" />
            </div>
          </div>
        )}
        {selected === 2 && (
          <div className="bg-light_green py-16 px-10 sm:px-20 flex items-center justify-between md:flex-row flex-col">
            <div className="md:w-[45%] sm:w-[70%] w-[90%] md:hidden block">
              <img src={left1} alt="" />
            </div>
            <div className="md:w-[50%] sm:w-[80%] w-[95%] md:mt-0 mt-10">
              <p className="text-white font-poppins font-normal text-[14px] md:text-[16px] tracking-normal">
                
              </p>
              <Typography variant="P_SemiBold_H3" className="text-white ">
                
              </Typography>

              <div className="my-5 flex flex-col gap-y-3">
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                    Enterprise Web and Mobile Solution
                  </Typography>
                </div>
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                  Cloud Computing
                  </Typography>
                </div>
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                  Big Data & Analytics
                  </Typography>
                </div>
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                  Internet-of-Things (IOT)
                  </Typography>
                </div>
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                  Cyber Security
                  </Typography>
                </div>
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                    Artificial Intelligence
                  </Typography>
                </div>
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                  Drones
                  </Typography>
                </div>
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                    Smart Meters
                  </Typography>
                </div>
                {/* <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                    
                  </Typography>
                </div> */}
              </div>

              {/* <Button
                className={'!bg-white !text-light_green mt-5'}
                shape={'round'}
              >
                Book Consulting
              </Button> */}
            </div>
            <div className="w-[45%] md:block hidden">
              <img src={left1} alt="" />
            </div>
          </div>
        )}
        {selected === 3 && (
          <div className="bg-light_red py-16 px-10 sm:px-20 flex items-center justify-between md:flex-row flex-col">
            <div className="md:w-[45%] sm:w-[70%] w-[90%] md:hidden block">
              <img src={solar} alt="" />
            </div>
            <div className="md:w-[50%] sm:w-[80%] w-[95%] md:mt-0 mt-10">
              <p className="text-white font-poppins font-normal text-[14px] md:text-[16px] tracking-normal">
                
              </p>
              <Typography variant="P_SemiBold_H3" className="text-white ">
                
              </Typography>

              <div className="my-5 flex flex-col gap-y-3">
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                    Product Design
                  </Typography>
                </div>
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                    State-Of-The-Art Technologies
                  </Typography>
                </div>
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                    Support & Maintenance
                  </Typography>
                </div>
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                    Quality Assurance
                  </Typography>
                </div>
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                    Product Testing 
                  </Typography>
                </div>
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                    User and Development Team Training
                  </Typography>
                </div>
              </div>

              {/* <Button
                className={'!bg-white !text-light_red mt-5'}
                shape={'round'}
              >
                
              </Button> */}
            </div>
            <div className="w-[45%] md:block hidden">
              <img src={solar} alt="" />
            </div>
          </div>
        )}
        {selected === 4 && (
          <div className="bg-light_red py-16 px-10 sm:px-20 flex items-center justify-between md:flex-row flex-col">
            <div className="md:w-[45%] sm:w-[70%] w-[90%] md:hidden block">
              <img src={left2} alt="" />
            </div>
            <div className="md:w-[50%] sm:w-[80%] w-[95%] md:mt-0 mt-10">
              <p className="text-white font-poppins font-normal text-[14px] md:text-[16px] tracking-normal">
                
              </p>
              <Typography variant="P_SemiBold_H3" className="text-white ">
                
              </Typography>

              <div className="my-5 flex flex-col gap-y-3">
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                    Integration with Third Party Applications
                  </Typography>
                </div>
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                    Integration through Various Middleware Tools
                  </Typography>
                </div>
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                    Efficient Data Exhange
                  </Typography>
                </div>
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                    Quality Solution
                  </Typography>
                </div>
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                    IoT Device Integrations
                  </Typography>
                </div>
                <div className="flex items-center">
                  <CheckCircleIcon className="text-white  w-[20px]" />
                  <Typography variant="P_Regular_H4" className="text-white ml-2">
                    Process Automation
                  </Typography>
                </div>
              </div>

              {/* <Button
                className={'!bg-white !text-light_red mt-5'}
                shape={'round'}
              >
                
              </Button> */}
            </div>
            <div className="w-[45%] md:block hidden">
              <img src={left2} alt="" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Remote;
