import React, { useRef } from 'react';

import { useInView } from 'framer-motion';

// ** import assets
import bannerBackground from '@assets/svg/world.svg';

// ** import components
import Header1 from '@components/Headers/Header1';
import Typography from '@shared/Typography';
import { bottom_top_variants } from '@src/utils/animations';

function Hero() {
  const ref = useRef(null);
  const isInView = useInView(ref);

  return (
    <Header1 bgImg={bannerBackground}>
      <div
        ref={ref}
        className="flex flex-col w-full justify-center max-w-4xl mx-auto"
      >
        <div className="overflow-hidden">
          <Typography
            mAnimate={{
              mVariants: bottom_top_variants,
              isInView: isInView,
              mDelay: 0,
            }}
            variant="P_Bold_H1"
            className="text-primary-black block"
          >
            Join our world-class team
          </Typography>
        </div>
        <div className="overflow-hidden">
          <Typography
            mAnimate={{
              mVariants: bottom_top_variants,
              isInView: isInView,
              mDelay: 0,
            }}
            variant="P_Bold_H1"
            className="text-primary-blue -mt-3 block"
          >
            of creators & dreamers
          </Typography>
        </div>
        <div className="overflow-hidden">
          <Typography
            mAnimate={{
              mVariants: bottom_top_variants,
              isInView: isInView,
              mDelay: 0.1,
            }}
            variant="P_Medium_H5"
            className="text-primary-black mt-6 block"
          >
            Our philosophy is simple — hire a team of diverse, passionate people
            and foster a culture that empowers you to do your best work. .
          </Typography>
        </div>
      </div>
    </Header1>
  );
}
export default Hero;
