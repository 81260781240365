import React from 'react';
import PropTypes from 'prop-types';

function TextArea({ label, name, placeholder, register, required, error, onChange, value }) {
  return (
    <div>
      <label htmlFor={name} className="text-base font-medium text-gray-900">
        {label}
      </label>
      <div className="mt-2">
        <textarea
          {...register(name, { required })}
          type="text"
          resi
          id={name}
          required={required}
          value={value}
          onChange={onChange}
          placeholder={placeholder || `Enter your ${label.toLowerCase()}`}
          className="block w-full px-4 py-4 text-base text-gray-900 resize-none bg-white border border-gray-200 rounded-lg focus:outline-none focus:border-blue-600 focus:ring-1 focus:ring-blue-600"
        />
        {error && <span className="text-red-500">This field is required</span>}
      </div>
    </div>
  );
}

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  register: PropTypes.func.isRequired,
  required: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string, 

};

export default TextArea;
