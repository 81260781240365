import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { motion as m } from 'framer-motion';
import { left_right_variants } from '@src/utils/animations';

const Image = ({
  src,
  alt = 'image',
  className,
  link = '',
  target = '',
  animate,
}) => {
  const imageElement =
    animate !== undefined ? (
      <m.img
        initial="initial"
        animate={animate?.isInView && 'slidein'}
        variants={left_right_variants}
        custom={animate?.mDelay}
        viewport={{ once: true }}
        className={`  inline-block object-cover w-full h-full ${className}`}
        // className={`${className}`}
        src={src}
        alt={alt}
        // loading="lazy"
      />
    ) : (
      <img
        className={`  inline-block object-cover w-full h-full transition-all duration-300 transform group-hover:scale-125 ${className}`}
        // className={`${className}`}
        src={src}
        alt={alt}
        // loading="lazy"
      />
    );

  return (
    <div className="flex-shrink-0  aspect-w-4 aspect-h-3 overflow-hidden">
      {link ? (
        <Link to={link} target={target}>
          {imageElement}
        </Link>
      ) : (
        imageElement
      )}
    </div>
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  link: PropTypes.string,
  target: PropTypes.string,
};

export default Image;
