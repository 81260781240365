import React from 'react';

// ** import sub page content/components
import SmartEU from './SmartEU';

//**importing components */
import Costumers from '@components/Costumers';
import ContactUs from '@components/ContactUs';
import OurServices from '@components/OurServices';
import Testimonials from '@src/components/Testimonials';

//**import image */
import msgThumpSubSvg from '@assets/svg/home/msgThumpSub.svg';
import mobileShopSvg from '@assets/svg/home/mobileShop.svg';
import socialMsgSvg from '@assets/svg/home/socialMsg.svg';
import startGiftSvg from '@assets/svg/home/startGift.svg';
import globalNetworkSvg from '@assets/svg/home/globalNetwork.svg';
import customerSupportSvg from '@assets/svg/home/customerSupport.svg';
import ProductHighlights from '../Home/ProductHighlights';
import Hero from './Hero';

const servicesData = [
  {
    img: msgThumpSubSvg,
    title: 'Billing & Collection Operations',
    description:
      'The core application allows Utilities to record meter reading, Generate Bills & Collect Bill Payments thereby providing a transparency.',
  },
  {
    img: mobileShopSvg,
    title: 'Field workers and Supervisors',
    description:
      'The field users get their respective dashboards with details about Pending Jobs, Approvals, Service Orders, Appointments, Scheduling and routing etc.',
  },
  {
    img: socialMsgSvg,
    title: 'Reduces Billing and Collection Costs',
    description:
      'Lorem ipsum dolor sit amet consectetur Interdum id augue sed sed lectus dolor mauris at. Odio ac morbi nisl interdum pellentesque non.',
  },
  {
    img: startGiftSvg,
    title: 'Optimized operations for greater productivity.',
    description:
      'Lorem ipsum dolor sit amet consectetur Interdum id augue sed sed lectus dolor mauris at. Odio ac morbi nisl interdum pellentesque non.',
  },
  {
    img: globalNetworkSvg,
    title: 'Effective Complaints and issue tracking',
    description:
      'Lorem ipsum dolor sit amet consectetur Interdum id augue sed sed lectus dolor mauris at. Odio ac morbi nisl interdum pellentesque non.',
  },
  {
    img: customerSupportSvg,
    title: '100% Transparency & Openness System',
    description:
      'Loremm ipsum dolor sit amet consectetur Interdum id augue sed sed lectus dolor mauris at. Odio ac morbi nisl interdum pellentesque non.',
  },
];

function Home() {
  return (
    <div className="">
      <Hero />
      <ProductHighlights />
      {/* <ContactUs /> */}
    </div>
  );
}

export default Home;
