import React from 'react';

import { motion as m } from 'framer-motion';

// ** import images
import heroImg from '@assets/Images/home/Band.jpg';

// ** import components
import Button from '@shared/Button';
import Typography from '@shared/Typography';

// animations
import { left_right_variants } from '@src/utils/animations';


function Hero() {
  return (
    <div className="relative pt-40 pb-12 bg-primary-white xl:pt-48 sm:pb-16 lg:pb-32 xl:pb-48 2xl:pb-56">
      <div className="absolute inset-0 w-full h-100%">
        <m.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, ease: 'easeIn' }}
          className="object-fit:cover w-full h-full scale-x"
          src={heroImg}
          alt="hero img"
        />
      </div>

      <div className=" w-full h-full top-0 left-0 absolute backdropGradient"></div>
      <div className="relative">
        <div className="px-6 mx-auto sm:px-8 lg:px-12 max-w-7xl">
          <div className="flex flex-col w-full lg:w-[65%] xl:w-1/1 overflow-hidden">
            {/* <Typography
              mAnimate={{
                mVariants: left_right_variants,
                isInView: null,
                mDelay: 0.4,
              }}
              variant="P_Bold_Strong"
              className="text-white max-w-3xl"
              maxLines={2}
            > */}
            <m.h1
              initial="initial"
              animate="slidein"
              custom={0.4}
              variants={left_right_variants}
              className=" text-7xl font-bold text-white "
            >
              <span className=" !font-extrabold"> ABHARTECH </span> 

            </m.h1>
            {/* </Typography> */}
            <Typography
              mAnimate={{
                mVariants: left_right_variants,
                isInView: null,
                mDelay: 0.5,
              }}
              variant="P_Regular_H5"
              className="text-white mt-4 max-w-xl"
            >
              Unleashing New-Age Enterprise Business Applications With Increased Flexibility, Added Value And Increased Returns.
            </Typography>
            <m.div
              initial="initial"
              animate="slidein"
              custom={0.7}
              variants={left_right_variants}
              className="flex items-center mt-6 space-x-3 sm:space-x-4"
            >
              {/* <Button
                className=" !bg-white !text-primary-blue "
                shape={'round'}
              >
                Get Started
              </Button> */}
            </m.div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Hero;
