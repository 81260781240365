import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { bottom_top_variants } from '@src/utils/animations';

function Card({
  header,
  clickable,
  headerExtra,
  footer,
  children,
  className,
  idx = 0,
  delay = 0,
  noAnimation,
  ...props
}) {
  return (
    <motion.div
      className={`my-2 hover:shadow-md rounded-lg p-6 border-[1px] border-light-blue mx-auto ${className}`}
      initial="initial"
      whileInView="visible"
      variants={!noAnimation ? bottom_top_variants : null}
      custom={delay + idx * 0.08}
      whileHover={clickable ? { scale: 1.01 } : null}
      whileTap={clickable ? { scale: 0.98 } : null}
    >
      <div className="justify-between mb-4 " {...props}>
        {header && <div>{header}</div>}
        {headerExtra && <div>{headerExtra}</div>}
      </div>
      <div>{children}</div>
      {footer && <div className="mt-4">{footer}</div>}
    </motion.div>
  );
}

Card.propTypes = {
  header: PropTypes.string.isRequired,
  headerExtra: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node.isRequired,
  clickable: PropTypes.bool,
};

Card.defaultProps = {
  clickable: false,
};

export default Card;
