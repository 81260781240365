import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion, useAnimation } from 'framer-motion';

const InfiniteImageSlider = ({ images, speed = 25, className = '' }) => {
  const controls = useAnimation();

  useEffect(() => {
    const xStart = '-180px';
    const xEnd = -180 * images.length + 'px';

    controls.start({
      x: [xStart, xEnd],
      transition: {
        duration: speed,
        ease: 'linear',
        repeat: Infinity,
      },
    });
  }, [controls, images.length, speed]);

  return (
    <div
      className={`relative w-full h-40 bg-white shadow-md overflow-hidden ${className}`}
    >
      <motion.ul
  className="flex absolute top-0 bottom-0 left-1/2 transform -translate-x-1/2"
  style={{ width: `calc(180px * ${images.length * 2})` }}
  animate={controls}
>
        {images.map((image, index) => (
          <li
            key={index}
            className="flex items-center justify-center w-44 h-40"
          >
            <img src={image} alt="" className="max-w-full max-h-full" />
          </li>
        ))}
        {images.map((image, index) => (
          <li
            key={index + images.length}
            className="flex items-center justify-center w-44 h-40"
          >
            <img src={image} alt="" className="max-w-full max-h-full" />
          </li>
        ))}
      </motion.ul>
      <div className="absolute inset-y-0 left-0 w-10 sm:w-28 md:w-44 h-full bg-gradient-to-r from-white via-white to-transparent z-10"></div>
      <div className="absolute inset-y-0 right-0 w-10 sm:w-28 md:w-44 h-full bg-gradient-to-l from-white via-white to-transparent z-10"></div>
    </div>
  );
};

InfiniteImageSlider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  speed: PropTypes.number,
  className: PropTypes.string,
};

export default InfiniteImageSlider;
