import React, { useEffect, useRef, useState } from 'react';

import { motion as m, useInView } from 'framer-motion';

// ** import images
import smartEUImg from '@assets/Images/home/SmartEU.png';
import EnSmartImg from '@assets/Images/home/EnSmart.png';
import mProSMARTImg from '@assets/Images/home/mProSMART.png';

// ** import icons
import { Icon } from '@iconify/react';

// ** import components
import Button from '@shared/Button';
import Typography from '@shared/Typography';
import Image from '@src/components/shared/Image';

// animations
import { bottom_top_variants } from '@src/utils/animations';
import { Link } from 'react-router-dom';

import BronchureForm from '../../components/BronchureForm';

// Someone pls check why this staggerchildren not working i've implemented it as per the docs even then they are not staggered
const variants = {
  visible: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  hidden: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const cardData = [
  {
    title: 'SmartEU',
    desc: 'Across the world,  there has been a major focus on providing Continuous & Quality Supply of Electricity, Gas and Water to the Citizens and also to Reduce the Billing and Collection Losses.',
    img: smartEUImg,
    path: '/smarteu'
  },
  {
    title: 'EnSmart',
    desc: ' Due to emerging growth developments and growing demands Electricity has become the priority across the world.',
    img: EnSmartImg,
    path: '',
  },
  {
    title: 'mProSMART',
    desc: ' Infrastructure Industry Globally has registered great progress in the recent years. Future growth is anticipated and expected in several areas and there has been huge proposed investments in the coming years.',
    img: mProSMARTImg,
    path: '/mprosmart'
  },
];

function ProductHighlights() {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [attachmentsIndex, setAttachmentsIndex] = useState(null);
  return (
    <section className=" bg-primary-white ">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div
          ref={ref}
          className="text-center flex flex-col w-fit mx-auto overflow-hidden"
        >
          <div className="pt-6 inline-flex overflow-hidden">
            <Typography
              mAnimate={{
                mVariants: bottom_top_variants,
                isInView: isInView,
                mDelay: 0,
              }}
              variant="P_SemiBold_H3"
              className="text-primary-black mx-auto"
            >
              Product Highlights
            </Typography>
          </div>
          <div className="inline-flex overflow-hidden">
            <Typography
              mAnimate={{
                mVariants: bottom_top_variants,
                isInView: isInView,
                mDelay: 0.2,
              }}
              variant="P_Regular_H6"
              className="mt-4 text-light-black"
            ></Typography>
          </div>
        </div>

        <div className="relative mt-1 pb-10">
          <m.div
            initial="hidden"
            whileInView="visible"
            variant={variants}
            className=" grid grid-cols-1 gap-6 mt-0 sm:grid-cols-2 lg:grid-cols-3 md:mt-0"
          >
            {cardData.map((card, idx) => (
              <m.div
                initial="initial"
                whileInView="visible"
                variants={bottom_top_variants}
                custom={0.3 + idx * 0.06}
                key={idx}
                className="bg-white shadow-xl rounded-xl group overflow-hidden"
              >
                <Image src={card.img} />
                <div className="p-8 sm:py-10 sm:px-10 flex flex-col ">
                  <Typography
                    variant="P_SemiBold_H5"
                    className=" text-primary-blue"
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    variant="P_SemiBold_H5"
                    className="mt-4 text-light-black"
                  >
                    {card.desc}
                  </Typography>
                  {card.path && (
                    <Link to={card.path}>
                      <Button
                        iconEnd={
                          <Icon icon="basil:arrow-right-solid" fontSize={32} />
                        }
                        className={'mt-4 text-primary-blue !px-0 max-w-fit'}
                        variant="plain"
                        position="start"
                      >
                        Know More
                      </Button>
                    </Link>
                  )}

                  <div className="button-container">
                    {idx === 0 && (
                      <Button
                        style={{ marginTop: '40px' }}
                        onClick={() => {
                          setDownloadModalOpen(true);
                          setAttachmentsIndex(0); // Set the attachments index for companyProfile.pdf
                        }}
                      >
                        <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                        Download
                      </Button>
                    )}
                    {/* {idx === 1 && (
                      <Button
                        style={{ marginTop: '165px' }}
                        onClick={() => {
                          setDownloadModalOpen(true);
                          setAttachmentsIndex(1); // Set the attachments index for companyProfile1.pdf
                        }}
                      >
                        <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                        Download
                      </Button>
                    )} */}
                    {idx === 2 && (
                      <Button
                        style={{ marginTop: '10px' }}
                        onClick={() => {
                          setDownloadModalOpen(true);
                          setAttachmentsIndex(2); // Set the attachments index for companyProfile2.pdf
                        }}
                      >
                        <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                        Download
                      </Button>
                    )}
                  </div>
                </div>
              </m.div>
            ))}
          </m.div>
        </div>
      </div>
      {downloadModalOpen && (
        <BronchureForm
          setOpen={setDownloadModalOpen}
          attachmentsIndex={attachmentsIndex}
        />
      )}
    </section>
  );
}
export default ProductHighlights;
