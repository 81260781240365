import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

//* *  import assets
import bannerBackground from '@assets/svg/connect/map.jpg';

import Image from '@src/components/shared/Image';

//**import components */
import Typography from '@src/components/shared/Typography';
import { useInView, motion as m } from 'framer-motion';
import { bottom_top_variants } from '@src/utils/animations';

function Banner() {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const detailRef = useRef(null);
  const isDetailsInView = useInView(detailRef);

  return (
    <div className="overflow-x-hidden bg-white-50">
      <div className="px-5 bg-primary-white">
        <div ref={ref} className="mx-auto text-center">
          <div className="overflow-hidden mt-6">
            <Typography
              mAnimate={{
                mVariants: bottom_top_variants,
                isInView: isInView,
                mDelay: 0,
              }}
              variant="P_SemiBold_H2"
              className=" flex  text-primary-black justify-center"
            >
              We’re a distributed team
            </Typography>
          </div>
          <div className="overflow-hidden mt-2">
            <Typography
              mAnimate={{
                mVariants: bottom_top_variants,
                isInView: isInView,
                mDelay: 0.1,
              }}
              variant="P_Regular_H5"
              className=" flex   text-mid-black justify-center"
            >
              We have offices and teams all around the world.
            </Typography>
          </div>
        </div>
        <div className="relative">
          <div className="relative mx-auto">
            <m.div
              initial="initial"
              whileInView="visible"
              variants={bottom_top_variants}
              custom={0.6}
              viewport={{ once: true }}
              className="lg:max-w-6xl lg:mx-auto p-10  bg-white rounded-xl"
            >
              <Image
                className="transform scale-150 sm:scale-110 "
                src={bannerBackground}
                alt=""
              />
            </m.div>
          </div>
        </div>
      </div>
      {/* <section className="py-10 bg-white sm:pt-16 ">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 ">
          <div
            ref={detailRef}
            className="flex md:flex-row flex-col mx-auto w-full justify-between"
          >
            <div className=" md:pb-0 pb-4">
              <div className="overflow-hidden">
                <Typography
                  mAnimate={{
                    mVariants: bottom_top_variants,
                    isInView: isDetailsInView,
                    mDelay: 0,
                  }}
                  variant="P_Medium_H5"
                  className="flex text-primary-black justify-center"
                >
                  Support
                </Typography>
              </div>
              <div className="overflow-hidden">
                <Typography
                  mAnimate={{
                    mVariants: bottom_top_variants,
                    isInView: isDetailsInView,
                    mDelay: 0.1,
                  }}
                  variant="P_Regular_H6"
                  className="flex text-mid-black justify-center "
                >
                  Our friendly team is here to help.
                </Typography>
              </div>
              <div className="overflow-hidden">
                <m.div
                  initial="initial"
                  animate={isDetailsInView && 'visible'}
                  variants={bottom_top_variants}
                  custom={0.2}
                  viewport={{ once: true }}
                  className=""
                >
                  <Link
                    to="#"
                    className="text-primary-blue cursor-pointer flex justify-center"
                  >
                    support@abhartech.com
                  </Link>
                </m.div>
              </div>
            </div>
            <div className=" md:pb-0 pb-4">
              <div className="overflow-hidden">
                <Typography
                  mAnimate={{
                    mVariants: bottom_top_variants,
                    isInView: isDetailsInView,
                    mDelay: 0.1,
                  }}
                  variant="P_Medium_H5"
                  className="flex text-primary-black justify-center"
                >
                  Sales
                </Typography>
              </div>
              <div className="overflow-hidden">
                <Typography
                  mAnimate={{
                    mVariants: bottom_top_variants,
                    isInView: isDetailsInView,
                    mDelay: 0.2,
                  }}
                  variant="P_Regular_H6"
                  className="flex text-mid-black justify-center"
                >
                  Questions or queries? Get in touch!
                </Typography>
              </div>
              <div className="overflow-hidden">
                <m.div
                  initial="initial"
                  animate={isDetailsInView && 'visible'}
                  variants={bottom_top_variants}
                  custom={0.3}
                  viewport={{ once: true }}
                  className=""
                >
                  <Link
                    to="#"
                    className="text-primary-blue cursor-pointer flex justify-center"
                  >
                    sales@abhartech.com
                  </Link>
                </m.div>
              </div>
            </div>
            <div className=" md:pb-0 pb-4">
              <div className="overflow-hidden">
                <Typography
                  mAnimate={{
                    mVariants: bottom_top_variants,
                    isInView: isDetailsInView,
                    mDelay: 0.2,
                  }}
                  variant="P_Medium_H5"
                  className="flex text-primary-black justify-center"
                >
                  Phone
                </Typography>
              </div>
              <div className="overflow-hidden">
                <Typography
                  mAnimate={{
                    mVariants: bottom_top_variants,
                    isInView: isDetailsInView,
                    mDelay: 0.3,
                  }}
                  variant="P_Regular_H6"
                  className="flex text-mid-black justify-center"
                >
                  Mon-Fri from 8am to 5pm.
                </Typography>
              </div>
              <div className="overflow-hidden">
                <m.div
                  initial="initial"
                  animate={isDetailsInView && 'visible'}
                  variants={bottom_top_variants}
                  custom={0.4}
                  viewport={{ once: true }}
                  className=""
                >
                  <Link
                    to="#"
                    className="text-primary-blue cursor-pointer flex justify-center"
                  >
                    +91 99806 17533
                  </Link>
                </m.div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}

export default Banner;
