import React from 'react';

// ** import sub page content/components
import Hero from './Hero';
import JobInfo from './JobInfo';
import Team from './Team';
// import NewsLetter from '@src/components/NewsLetter';


function Home() {
  return (
    <div>
      <Hero />
      <JobInfo />
      <Team />
      {/* <NewsLetter/> */}
    </div>
  );
}

export default Home;
