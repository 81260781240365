import React, { useRef, useState } from 'react';

import { motion as m, useInView } from 'framer-motion';

// ** import components
import Button from '@shared/Button';
import Typography from '@shared/Typography';
import Image from '@src/components/shared/Image';

// ** import images
import bgImg from '@assets/Images/bg/e3.jpg';
import { bottom_top_variants } from '@src/utils/animations';
import BronchureForm from '../BronchureForm';

function ContactUs() {
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const ref = useRef(null);
  const isInView = useInView(ref);

  return (
    <section className="relative bg-primary-white ">
      {/* <div className="absolute inset-0 ">
        <Image src={bgImg} className="object-cover h-screen max-h-[460px] " />
      </div> */}
<dv></dv>
      <div className="relative px-8   w-full h-full bg-blue-700">
        <div
          ref={ref}
          className=" mx-auto text-center flex flex-col overflow-hidden"
        >
          <Typography
            mAnimate={{
              mVariants: bottom_top_variants,
              isInView: isInView,
              mDelay: 0,
            }}
            variant="P_SemiBold_H2"
            className="text-primary-white"
          >
            Vision and Mission
          </Typography>
          <Typography
            mAnimate={{
              mVariants: bottom_top_variants,
              isInView: isInView,
              mDelay: 0.1,
            }}
            variant="P_Regular_H5"
            className="text-primary-white mt-6"
          >
            Our <b>Vision</b> is to be the leading and most reliable partner by choice in Technology consulting and business.
          </Typography>
          <Typography
            mAnimate={{
              mVariants: bottom_top_variants,
              isInView: isInView,
              mDelay: 0.1,
            }}
            variant="P_Regular_H5"
            className="text-primary-white mt-6"
          >

            Our <b>Mission </b>is to help our Customers simplify their business processes (internally and externally) with measurable technology/strategic implementation success leading to Customer Delight, Business Excellence and Rewards.
          </Typography>
          <Typography
            mAnimate={{
              mVariants: bottom_top_variants,
              isInView: isInView,
              mDelay: 0.1,
            }}
            variant="P_Regular_H5"
            className="text-primary-white mt-6"
          >

            Our <b>Values</b> TeamWork, Integrity, Innovation, Customer First and Excellence.
          </Typography>
          <div className="overflow-hidden">
            <m.div
              initial="initial"
              animate={isInView && 'visible'}
              variants={bottom_top_variants}
              viewport={{ once: true }}
              custom={0.2}
              className="mt-8"
            >
              {/* <Button
                onClick={() => setDownloadModalOpen(true)}
                className={'!bg-primary-white !text-primary-blue'}
              >

              </Button> */}
            </m.div>
          </div>
        </div>
      </div>
      {downloadModalOpen && <BronchureForm setOpen={setDownloadModalOpen} />}
    </section>
  );
}

export default ContactUs;
