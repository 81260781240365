import { toast } from 'react-hot-toast';

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** copy text
export const copyText = (text, alertMsg) => () => {
  const cb = navigator.clipboard;
  cb.writeText(text).then(() => {
    if (alertMsg) toast.success(alertMsg);
  });
};
