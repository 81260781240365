import Slider from 'react-slick';
// Import css files for slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FullWidthBgImage } from './FullWidthBgImage';
import { FiArrowRightCircle, FiArrowLeftCircle } from 'react-icons/fi';
import { useResponsive } from './useResponsive';
import { useRef } from 'react';
import React from 'react';
import './CustomModal.css';

export const SliderComp = ({
  data,
  label,
  heading,
  subheading,
  slideNum,
  autoplay,
  autoplaySpeed,
}) => {
  const { screenType } = useResponsive();
  const sliderRef = useRef(1);
  const settings = {
    arrows: true,
    dots: true,
    swipeToSlide: true,
    // infinite: false,
    // autoplay: autoplay,
    autoplaySpeed: autoplaySpeed,
    slidesToShow: screenType === 'MOBILE' ? 1 : slideNum,
    slidesToScroll:2,
  };

  return (
    <>
      <div style={{ marginBottom: 20, marginLeft: 10 }}>
        <h2>{heading}</h2>
        <p>{subheading}</p>
      </div>
      <div
        className="sliderContainer"
        style={{
          position: 'relative',
          width: '100%',
          height: '500px',
          overflow: 'hidden',
        }}
      >
        {screenType !== 'MOBILE' && (
          <div
            onClick={() => sliderRef.current.slickPrev()}
            className="prev"
            style={{
              position: 'fixed',
              left: '10px',
              zIndex: 2,
              top: '50%',
              transform: 'translateY(-50%)',
              cursor: 'pointer',
              backgroundColor: '#fff', // Adjust background color for visibility
              borderRadius: '50%',
              padding: '5px',
              overflow: 'hidden',
            }}
          >
            <FiArrowLeftCircle style={{ fontSize: 24, color: 'black' }} />
          </div>
        )}
        <Slider {...settings} ref={sliderRef}>
          {data?.length > 0 &&
            data.map((item, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div className="sliderImage">
                  <FullWidthBgImage
                    image={item.image}
                    label={label}
                    borderRadius={'10px'}
                  />
                </div>
                <div
                  className="sliderContent"
                  style={{
                    height: "400px",
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "center",
                    borderRadius: '10px',
                  }}
                >
                  {/* Icon above the title */}
                  <img
                    src={item.image}
                    alt="image"
                    className='w-[11rem] h-[6rem]'
                  />

                  <h2
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      color: '#333',
                      marginBottom: '10px',
                    }}
                  >
                    {item.title}
                  </h2>
                  <p
                    style={{
                      fontSize: '16px',
                      color: '#555',
                      lineHeight: '1.6',
                    }}
                  >
                    {item.content}
                  </p>
                </div>
              </div>
            ))}
        </Slider>
        {screenType !== 'MOBILE' && (
          <div
            onClick={() => sliderRef.current.slickNext()}
            className="next"
            style={{
              position: 'fixed',
              right: '50px',
              zIndex: 2,
              top: '50%',
              transform: 'translateY(-50%)',
              cursor: 'pointer',
              backgroundColor: '#fff', // Adjust background color for visibility
              borderRadius: '50%',
              padding: '5px',
            }}
          >
            <FiArrowRightCircle style={{ fontSize: 24, color: 'black' }} />
          </div>
        )}
      </div>
    </>
  );
};
