import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { motion as m } from 'framer-motion';

function Typography({
  mAnimate,
  variant,
  children,
  className,
  maxLines,
  link = '',
  target = '',
  animate = '',
}) {
  const getClassName = () => {
    switch (variant) {
      // "Bold" - 700
      case 'P_Bold_Strong':
        return 'font-poppins font-bold text-[62px] md:text-[82px] tracking-normal';
      case 'P_Bold_H1':
        return 'font-poppins font-bold text-[40px] md:text-[62px] tracking-normal';
      case 'P_Bold_H2':
        return 'font-poppins font-bold text-[32px] md:text-[49px] tracking-normal';
      case 'P_Bold_H3':
        return 'font-poppins font-bold text-[24px] md:text-[39px] tracking-normal';
      case 'P_Bold_H4':
        return 'font-poppins font-bold text-[20px] md:text-[25px] tracking-normal';
      case 'P_Bold_H5':
        return 'font-poppins font-bold text-[16px] md:text-[20px] tracking-normal';
      case 'P_Bold_H6':
        return 'font-poppins font-bold text-[14px] md:text-[16px] tracking-normal';

      // "SemiBold" - 600
      case 'P_SemiBold_H1':
        return 'font-poppins font-semibold text-[40px] md:text-[62px] tracking-normal';
      case 'P_SemiBold_H2':
        return 'font-poppins font-semibold text-[32px] md:text-[49px] tracking-normal';
      case 'P_SemiBold_H3':
        return 'font-poppins font-semibold text-[24px] md:text-[39px] tracking-normal';
      case 'P_SemiBold_H_T':
        return 'font-poppins font-semibold text-[24px] md:text-[31px] tracking-normal';
      case 'P_SemiBold_H4':
        return 'font-poppins font-semibold text-[20px] md:text-[25px] tracking-normal';
      case 'P_SemiBold_H5':
        return 'font-poppins font-semibold text-[16px] md:text-[20px] tracking-normal';
      case 'P_SemiBold_H6':
        return 'font-poppins font-semibold text-[14px] md:text-[16px] tracking-normal';

      // "Medium" - 500
      case 'P_Medium_H1':
        return 'font-poppins font-semibold text-[40px] md:text-[62px] tracking-normal';
      case 'P_Medium_H2':
        return 'font-poppins font-semibold text-[32px] md:text-[49px] tracking-normal';
      case 'P_Medium_H3':
        return 'font-poppins font-semibold text-[24px] md:text-[39px] tracking-normal';
      case 'P_Medium_H4':
        return 'font-poppins font-semibold text-[20px] md:text-[25px] tracking-normal';
      case 'P_Medium_H5':
        return 'font-poppins font-semibold text-[16px] md:text-[20px] tracking-normal';
      case 'P_Medium_H6':
        return 'font-poppins font-semibold text-[14px] md:text-[16px] tracking-normal';

      // "Regular" - 400
      case 'P_Regular_H1':
        return 'font-poppins font-normal text-[40px] md:text-[62px] tracking-normal';
      case 'P_Regular_H2':
        return 'font-poppins font-normal text-[32px] md:text-[49px] tracking-normal';
      case 'P_Regular_H3':
        return 'font-poppins font-normal text-[24px] md:text-[39px] tracking-normal';
      case 'P_Regular_H4':
        return 'font-poppins font-normal text-[20px] md:text-[25px] tracking-normal';
      case 'P_Regular_H5':
        return 'font-poppins font-normal text-[16px] md:text-[20px] tracking-normal';
      case 'P_Regular_H6':
        return 'font-poppins font-normal text-[14px] md:text-[16px] tracking-normal';

      default:
        return '';
    }
  };

  const fontTypes = getClassName();

  const lineClampClass =
    maxLines > 0 ? `line-clamp-${maxLines.toString()}` : 'whitespace-normal';

  const getAnimationClass = () => {
    switch (animate) {
      case 'move':
        return 'hover:ml-1.5 transition-all duration-300';
      case 'underline':
        return 'hover:underline transition-all duration-300';
      default:
        return '';
    }
  };

  const animationClass = getAnimationClass();

  const content = (
    <m.span
      initial="initial"
      animate={
        mAnimate?.mVariants?.hasOwnProperty('slidein') &&
        mAnimate?.isInView === null
          ? 'slidein'
          : mAnimate?.mVariants?.hasOwnProperty('slidein') && mAnimate?.isInView
          ? 'slidein'
          : mAnimate?.isInView && 'visible'
      }
      custom={mAnimate?.mDelay}
      variants={mAnimate?.mVariants}
      // viewport={{ once: true }}
      className={`${fontTypes} ${className} ${lineClampClass} ${animationClass} overflow-wrap overflow-hidden leading-normal`}
    >
      {children}
    </m.span>
  );

  return link ? (
    <Link to={link} target={target} className="max-w-fit">
      {content}
    </Link>
  ) : (
    content
  );
}

Typography.propTypes = {
  variant: PropTypes.oneOf([
    // "Bold"
    'P_Bold_H1',
    'P_Bold_H2',
    'P_Bold_H3',
    'P_Bold_H4',
    'P_Bold_H5',
    'P_Bold_H6',
    // "SemiBold"
    'P_SemiBold_H1',
    'P_SemiBold_H2',
    'P_SemiBold_H3',
    'P_SemiBold_H4',
    'P_SemiBold_H5',
    'P_SemiBold_H6',
    // "Medium"
    'P_Medium_H1',
    'P_Medium_H2',
    'P_Medium_H3',
    'P_Medium_H4',
    'P_Medium_H5',
    'P_Medium_H6',
    // "Regular"
    'P_Regular_H1',
    'P_Regular_H2',
    'P_Regular_H3',
    'P_Regular_H4',
    'P_Regular_H5',
    'P_Regular_H6',
  ]).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  maxLines: PropTypes.number,
  animate: PropTypes.oneOf(['move', 'underline']),
};

Typography.defaultProps = {
  className: '',
  maxLines: 0,
  animate: '',
};

export default Typography;
