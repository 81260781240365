import React from 'react'


// **import components */
import ContactUs from '@src/components/ContactUs'

//**import sub components */
import { JobDetails } from './JobDetails'


const JobApply = () => {
 

  return (
    <div>
    <JobDetails/>
     {/* <ContactUs/> */}
    </div>
  )
}

export default JobApply