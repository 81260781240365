import React, { useRef, useEffect } from 'react';

import { motion as m, useInView } from 'framer-motion';

// ** import images
import logImg from '@assets/Images/logo/adbhar_logo_text.png';

// ** import components
import Typography from '@shared/Typography';
import Button from '@shared/Button';
import Image from '@shared/Image';

// animation variant
import {
  left_right_variants,
  top_bottom_variants,
} from '@src/utils/animations';

const ShortInfo = ({ mdelay = 0 }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  return (
    <section ref={ref} className="bg-light-blue">
      <div className="px-4 mx-auto sm:ps-6 lg:px-0 max-w-7xl">
        <div className=" grid grid-cols-1 lg:grid-cols-2 lg:gap-x-16 gap-y-12">
          <div className="pt-12 sm:pt-12 lg:pt-14 px-20 overflow-hidden">
            <m.div
              initial="initial"
              whileInView="slidein"
              custom={0 + mdelay}
              variants={left_right_variants}
              viewport={{ once: true }}
              className="w-full max-w-md mx-auto"
            >
              <Image link="/" className="w-full md:w-80 mx-[5rem] mt-[0rem] mb-[3rem]" src={logImg} alt="" />
            </m.div>
          </div>
          <div className="max-w-md  mx-auto flex flex-col text-center md:max-w-lg lg:max-w-none lg:mx-0 lg:text-left  md:self-center">
            <div className="inline-flex max-lg:mx-auto overflow-hidden">
              <Typography
                mAnimate={{
                  mVariants: top_bottom_variants,
                  isInView: isInView,
                  mDelay: 0 + mdelay,
                }}
                variant="P_Medium_H5"
                className="text-light-black"
              >
                
              </Typography>
            </div>
            <div className="inline-flex overflow-hidden">
              <Typography
                mAnimate={{
                  mVariants: top_bottom_variants,
                  isInView: isInView,
                  mDelay: 0.1 + mdelay,
                }}
                variant="P_SemiBold_H3"
                className="text-primary-black"
              >
                Solutions. Re-Valued.
              
              </Typography>
            </div>
            <div className="inline-flex overflow-hidden">
              <Typography
                mAnimate={{
                  mVariants: top_bottom_variants,
                  isInView: isInView,
                  mDelay: 0.2 + mdelay,
                }}
                variant="P_Regular_H6"
                className="text-light-black"
              >



                ABHARTECH's complete range of IT Products address the needs of 
                both Technology and Business requirements end-to-end to help
                organizations leverage New-Age, Leading-Edge Technologies for
                Business Establishment, Improvement and Returns. 
                </Typography>
            </div>
            <div className="overflow-hidden">
              <m.div
                initial="initial"
                animate={isInView && 'visible'}
                custom={0.4 + mdelay}
                variants={top_bottom_variants}
                viewport={{ once: true }}
              >
                {/* <Button
                  variant="default"
                  size="md"
                  block={false}
                  className="max-w-fit mt-8 mx-auto lg:mx-0"
                >
                  know more
                </Button> */}
              </m.div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShortInfo;
