import React, { useRef } from 'react';

import { motion as m, useInView } from 'framer-motion';

// ** import components
import Typography from '@shared/Typography';
import InfiniteImgSwiper from '@shared/InfiniteImgSwiper';

// ** import assets
import customer1Svg from '@assets/svg/home/customer/Accenture-logo.png';
import customer2Svg from '@assets/svg/home/customer/TSECL.png';
import customer3Svg from '@assets/svg/home/customer/inish.jpg';
import customer4Svg from '@assets/svg/home/customer/RZpay.jpg';
import customer5Svg from '@assets/svg/home/customer/Keralaltd.png';
import customer6Svg from '@assets/svg/home/customer/microsoft.png';
import customer7Svg from '@assets/svg/home/customer/iinesh.jpg';

import customer8Svg from '@assets/svg/home/customer/isk.jpg';
import customer9Svg from '@assets/svg/home/customer/RL.png';
import customer1 from '@assets/svg/home/customer/MMHH.jpg';
import customer2 from '@assets/svg/home/customer/Wp.jpg';
import customer3 from '@assets/svg/home/customer/GK.jpg';
import customer5 from '@assets/svg/home/customer/pdd.png';
import customer4 from '@assets/svg/home/customer/midc.png';

import { bottom_top_variants } from '@src/utils/animations';

function Costumers({ Title }) {
  const ref = useRef(null);
  const isInView = useInView(ref);
  return (
    <section className="bg-primary-white pt-8">
      <div ref={ref} className="max-w-7xl px-4 mx-auto sm:px-6 lg:px-8">
        <div className="text-center flex flex-col pb-14 overflow-hidden w-fit mx-auto">
          <Typography
            mAnimate={{
              mVariants: bottom_top_variants,
              isInView: isInView,
              mDelay: 0,
            }}
            variant="P_SemiBold_H3"
            className="text-primary-black"
          >
            {Title ?? 'Our Customers'}
          </Typography>
          <Typography
            mAnimate={{
              mVariants: bottom_top_variants,
              isInView: isInView,
              mDelay: 0.1,
            }}
            variant="P_Regular_H6"
            className="mt-2 text-dark-white"
          >
            Crafting client success stories, every day...
          </Typography>
        </div>
        <InfiniteImgSwiper
          images={[customer1, customer2, customer4, customer5, customer3 ,customer1Svg,
            customer2Svg,customer3Svg,
            customer9Svg,]}
          speed={25}
          className="shadow-none w-full "
        />
      </div>
      <div ref={ref} className="pb-6 max-w-7xl px-4 mx-auto sm:px-6 lg:px-8">
        <div className="text-center flex flex-col pb-14 overflow-hidden w-fit mx-auto">
          <Typography
            mAnimate={{
              mVariants: bottom_top_variants,
              isInView: isInView,
              mDelay: 0,
            }}
            variant="P_SemiBold_H3"
            className="text-primary-black"
          >
            {'Our Partners'}
          </Typography>
          <Typography
            mAnimate={{
              mVariants: bottom_top_variants,
              isInView: isInView,
              mDelay: 0.1,
            }}
            variant="P_Regular_H6"
            className="mt-2 text-dark-white"
          >
            Empowering Success Together
          </Typography>
        </div>
        <InfiniteImgSwiper
          images={[
            
            customer6Svg,
            customer5Svg,
            
            customer8Svg,
            customer4Svg,

            

            customer7Svg,

          ]}
          speed={25}
          className="shadow-none w-full "
        />
      </div>
    </section>
  );
}

export default Costumers;
