import React from 'react';

// Import pages using the correct alias
import Home from '@pages/Home';
import Company from '@pages/Company';
import Products from '@pages/Products';
import Services from '@pages/Services';
import Careers from '@src/pages/Careers/index.js';
import ContactUs from '@pages/ContactUs';
import JobApply from '@src/pages/JobApply.js';
import SmartEU from '@src/pages/Products/SmartEU';
import MProSmart from '@src/pages/Products/MProSmart';
import EnSmart from '@src/pages/Products/EnSmart';
import Amisp from '@pages/Amisp'
import Consulting from '@pages/Consulting'

const PublicPages = [
  {
    path: '/',
    element: <Home />,
    meta: {
      publicRoute: true,
    },
  },
  // {
  //   path: '/company',
  //   element: <Company />,
  //   meta: {
  //     publicRoute: true,
  //   },
  // },
  {
    path: '/products',
    element: <Products />,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: '/services',
    element: <Services />,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: '/careers',
    element: <Careers />,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: '/careers/:id',
    element: <JobApply/>,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: '/contact-us',
    element: <ContactUs />,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: '/smarteu',
    element: <SmartEU />,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: '/mprosmart',
    element: <MProSmart />,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: '/ensmart',
    element: <EnSmart />,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: '/amisp',
    element: <Amisp />,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: '/consulting',
    element: <Consulting />,
    meta: {
      publicRoute: true,
    },
  },
];

export default PublicPages;
