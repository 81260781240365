import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-hot-toast';

// ** import shared components
import TextArea from '@shared/TextArea';
import TextInput from '@shared/TextInput';
import EmailInput from '@shared/EmailInput';
import Button from '../shared/Button';
import Typography from '../shared/Typography';

//**third party library */
import emailjs from '@emailjs/browser';
import PhoneInput from 'react-phone-input-2';
import "react-phone-input-2/lib/style.css";

// ** import env
import { env } from '@config/index';
import { useState } from 'react';
import axios from '../../axios'

function ContactForm() {
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: ""
  })
  const [mobile, setMobile] = useState('')
  const [errtatus, setErrtatus] = useState(false)
  const [loading, setLoading] = useState(false)



  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const form = useRef();

  const onSubmit = async (data) => {
    console.log(data);
    try {
      await emailjs
        .sendForm(env.SERVICE_ID, env.TEMPLATE_ID, form.current, env.PUBLIC_KEY)
        .then((result) => {
          console.log(result.text);
          toast.success('Email sent successfully');
          reset();
        })
        .catch((error) => {
          console.error(error);
          const errorMessage = error.message || 'An error occurred.';
          toast.error(errorMessage);
        });
    } catch (error) {
      console.error(error);
      const errorMessage = error.message || 'An error occurred.';
      toast.error(errorMessage);
    }
  };

  const handelchange = (e) => {
    const { name, value } = e.target;

    setUserInfo((pre) => {
      return {
        ...pre,
        [name]: value,
      };
    });
  };

  const handelsubmit = async () => {
    const Name = `${userInfo.firstName} ${userInfo.lastName}`
    const Email = userInfo.email
    const Message = userInfo.message
    const Mobile = mobile

    
    if (Mobile === undefined || Mobile === '' || Mobile.length < 10) {
      return setErrtatus(true)
    }

    setLoading(true)
    const { data } = await axios.post('/contact', {
      name: Name,
      email: Email,
      mobile: Mobile,
      message: Message
    })
    if (data.success) {
      toast.success('Successfully sended')
      setUserInfo({
        firstName: "",
        lastName: "",
        message: "",
        email: ""
      })
      setMobile("")
    setLoading(false)
      setErrtatus(false)
      return
    } else {
    setLoading(false)
   return toast.error(`${data.message}`)

    }

  }

  return (
    <div>
      <div className="p-6 sm:p-8">
        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit(onSubmit)
            handelsubmit()
          }}
          className="space-y-6"
          ref={form}
        >
          <div className="flex md:flex-row flex-col ">
            <div className="md:me-4 ms-0">
              <TextInput
                label="First name"
                name="firstName"
                register={register}
                required
                error={errors.fullName}
                onChange={handelchange}
                value={userInfo.firstName}


              />
            </div>
            <div className="md:ms-4 ms-0 mt-6 md:mt-0">
              <TextInput
                label="Last name"
                name="lastName"
                register={register}
                required
                error={errors.lastName}
                onChange={handelchange}
                value={userInfo.lastName}

              />
            </div>
          </div>

          <EmailInput
            label="Email"
            name="email"
            register={register}
            required
            error={errors.email}
            onChange={handelchange}
            value={userInfo.email}


          />

          <div>
            <label htmlFor="phone-input">Phone Number</label>
            <PhoneInput
                placeholder="Phone number"
                country={"in"}
                value={mobile}
                className=" customInputSize block w-full bg-white  text-base text-[#61748E] border border-gray-200 rounded-xl focus:outline-none focus-within:border-primary focus-within:ring-1 focus:ring-primary"
                onChange={setMobile}
                name="phone"
                defaultCountry={"in"}
        
        />
        {errtatus && mobile === undefined && (
                  <p className="error-message text-danger ">Invalid Phone</p>
                )}
                  {errtatus && mobile === '' && (
                  <p className="error-message text-danger ">Invalid Phone</p>
                )}
                 {errtatus &&  mobile?.length > 0 && mobile?.length < 10 && (
                  <p className="error-message text-danger ">Enter correct mobile number</p>
                )}

            <div className="mt-6">
              <TextArea
                label="Description"
                name="message"
                placeholder="Write us your question here..."
                register={register}
                required={true}
                error={errors.message}
                onChange={handelchange}
                value={userInfo.message}


              />
            </div>
          </div>
          {/* <div className="pb-3">
            <input
              type="checkbox"
              required
              className="border-1 w-5   border-light-gray"
            />
            <Typography variant="P_Regular_H6" className="ps-3 ">
              You agree to our friendly{' '}
              <Link to="#" className="underline cursor-pointer ">
                {' '}
                privacy policy
              </Link>
              .
            </Typography>
          </div> */}
          {
                loading ? <Button
                  type="submit"
                  className=" mt-6 inline-flex items-center justify-center w-full px-5 py-3 text-base font-medium text-primary-white transition-all duration-200 bg-primary-blue border border-transparent rounded-xl hover:bg-primary-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-blue"
                >
                  Loading...
                </Button> : <Button
                  type="submit"
                  className=" mt-6 inline-flex items-center justify-center w-full px-5 py-3 text-base font-medium text-primary-white transition-all duration-200 bg-primary-blue border border-transparent rounded-xl hover:bg-primary-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-blue"
                >
                 Send message
                </Button>
              }
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
