import React, { useRef } from 'react';

import { motion as m, useInView } from 'framer-motion';

// ** import components
import Typography from '@shared/Typography';
import Card from '@src/components/shared/Card';
import {
  bottom_top_variants,
  left_right_variants,
} from '@src/utils/animations';

const variants = {
  visible: {
    y: 0,
    opacity: 1,
  },
  hidden: {
    y: 10,
    opacity: 0,
  },
};

function OurServices({ servicesData, title, description }) {
  const ref = useRef(null);
  const isInView = useInView(ref);
  return (
    <section className="py-0 pb-8 px-11 bg-primary-white">
      <div ref={ref} className=" mx-auto  flex flex-col">
        <div className="flex flex-col text-center overflow-hidden w-fit mx-auto">
          <Typography
            mAnimate={{
              mVariants: bottom_top_variants,
              isInView: isInView,
              mDelay: 0,
            }}
            variant="P_SemiBold_H3"
            className=" text-primary-black"
          >
            {title}
          </Typography>
          <Typography
            mAnimate={{
              mVariants: bottom_top_variants,
              isInView: isInView,
              mDelay: 0.1,
            }}
            variant="P_Regular_H5"
            className=" text-dark-white mt-4"
          >
            {description}
          </Typography>
        </div>

        <div className="grid grid-cols-1 gap-8 max-w-6xl mx-auto sm:grid-cols-2 lg:grid-cols-3">
          {servicesData.map((item, idx) => (
            <Card
              key={idx}
              idx={idx}
              delay={0.3}
              className="overflow-hidden p-0 bg-primary-white rounded-xl !shadow-lg"
              clickable
            >
              <div className="px-2">
                <img
                  className="w-16 h-16 flex-shrink-0"
                  src={item.img}
                  alt={item.title}
                />
                <div className="flex flex-col">
                  <Typography
                    variant="P_SemiBold_H4"
                    className="text-primary-black mt-4"
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    variant="P_Regular_H6"
                    className="text-dark-white mt-4"
                  >
                    {item.description}
                  </Typography>
                </div>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
}
export default OurServices;
