import React, { useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

// ** import shared components
import TextArea from '@shared/TextArea';
import TextInput from '@shared/TextInput';
import EmailInput from '@shared/EmailInput';
import Button from './shared/Button';
import Typography from './shared/Typography';

//**third party library */
import PhoneInput from 'react-phone-input-2';
import "react-phone-input-2/lib/style.css";
import axios from '../axios';
import { toast } from 'react-hot-toast';

const ModalForm = ({ setShow, jobTitle }) => {
  let modalref = useRef();

  const [fileInfo, setFileInfo] = useState({ name: '', resume: '' });
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState('');
  const [fileError, setFileError] = useState('');
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    coverLetter: ""
  })
  const [mobile, setMobile] = useState('')
  const [errtatus, setErrtatus] = useState(false)
  const [loading, setLoading] = useState(false)


  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const form = useRef();

  //**handle submit */
  const onSubmit = async (data) => {
    if (!isChecked) {
      setError('Please agree to the terms.');
    } else if (!fileInfo.name) {
      setFileError('Please upload the resume');
    } else {
      console.log({ ...data, ...fileInfo, agreedToTerms: isChecked });
    }
  };

  //**handle change for file type input */
  const handleChange = async (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      const base64 = await convertToBase64(file);
      setFileInfo({ name: file.name, resume: base64 });
      setFileError('');
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(',')[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };


  //**handle change for checkbox type input */
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setError('');
  };

  const handelchange = (e) => {
    const { name, value } = e.target;

    setUserInfo((pre) => {
      return {
        ...pre,
        [name]: value,
      };
    });
  };

  const handlesubmit = async () => {
    const name = `${userInfo.firstName} ${userInfo.lastName}`
    const Mobile = mobile
    const email = userInfo.email
    const coverLettr = userInfo.coverLetter
    const file = fileInfo.resume

    if (Mobile === undefined || Mobile === '' || Mobile.length < 10) {
      return setErrtatus(true)
    }

    setLoading(true)

    if (fileInfo.resume.length !== 0) {
      const { data } = await axios.post('/sendEmailCompany', {
        email: email,
        name: name,
        mobile: Mobile,
        coverLetter: coverLettr,
        resume: file,
        job_category: jobTitle
      })

      if (data.success) {
        toast.success('Successfully applied')
        setErrtatus(false)
        setLoading(true)
        setShow(false)

        return
      } else {
      setLoading(false)
      return toast.error(`${data.message}`)

      }

    } else {
      setLoading(false)
      return setErrtatus(true)


    }




  }

  return (
    <>
      <div className="flex justify-center  w-full items-center overflow-x-hidden bg-[#00000052]  fixed inset-0 z-50 outline-none focus:outline-none">
        <div className=" w-full  mx-auto max-w-4xl">
          <div
            ref={modalref}
            className=" p-8 box-border w-10/12 md:w-3/4 relative top-[16rem] md:top-[13rem] mx-auto     border-0 rounded-lg shadow-lg   md:right-0  flex flex-col  bg-primary-white outline-none focus:outline-none"
          >
            <form
              onSubmit={(e) => {
                e.preventDefault()
                handleSubmit(onSubmit)
                handlesubmit()
              }}
              className="space-y-6"
              ref={form}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                className="absolute -right-2 -top-2  cursor-pointer"
                onClick={() => setShow(false)}
              >
                <g clip-path="url(#clip0_464_59069)">
                  <path
                    d="M20 0C9.01484 0 0 9.01484 0 20C0 30.9852 9.01484 40 20 40C30.9852 40 40 30.9852 40 20C40 9.01484 30.9852 0 20 0ZM29.9438 26.6295L26.6295 29.9438L20 23.3142L13.3705 29.9438L10.0562 26.6295L16.6858 20L10.0562 13.3705L13.3705 10.0562L20 16.6858L26.6295 10.0562L29.9438 13.3705L23.3142 20L29.9438 26.6295Z"
                    fill="#FF0000"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_464_59069">
                    <rect width="40" height="40" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <Typography
                variant="P_SemiBold_H3"
                className="flex text-primary-black ps-2 "
              >
                Apply for this position
              </Typography>
              <Typography
                variant="P_Regular_H5"
                className="flex text-mid-black ps-2"
              >
                {/* Lorem ipsum dolor sit amet consectetur. Eget. */}
              </Typography>

              <div className=" relative flex md:flex-row flex-col ">
                <div className="md:me-4  md:w-1/2 ms-0">
                  <TextInput
                    label="First name"
                    name="firstName"
                    register={register}
                    required
                    error={errors.fullName}
                    onChange={handelchange}
                  />
                </div>
                <div className="md:ms-4 ms-0 mt-6 md:mt-0 md:w-1/2">
                  <TextInput
                    label="Last name"
                    name="lastName"
                    register={register}
                    required
                    error={errors.lastName}
                    onChange={handelchange}

                  />
                </div>
              </div>

              <EmailInput
                label="Email"
                name="email"
                register={register}
                required
                error={errors.email}
                onChange={handelchange}

              />

              <div>
                <label htmlFor="phone-input">Phone Number</label>
                <PhoneInput
                  placeholder="Phone number"
                  country={"in"}
                  value={mobile}
                  className=" customInputSize block w-full bg-white  text-base text-[#61748E] border border-gray-200 rounded-xl focus:outline-none focus-within:border-primary focus-within:ring-1 focus:ring-primary"
                  onChange={setMobile}
                  name="phone"
                  defaultCountry={"in"}

                />

                {errtatus && mobile === undefined && (
                  <p className="error-message text-danger ">Invalid Phone</p>
                )}
                {errtatus && mobile === '' && (
                  <p className="error-message text-danger ">Invalid Phone</p>
                )}
                {errtatus && mobile?.length > 0 && mobile?.length < 10 && (
                  <p className="error-message text-danger ">Enter correct mobile number</p>
                )}

                <div className="mt-6">
                  <TextArea
                    label="Cover letter"
                    name="coverLetter"
                    placeholder="Type here....."
                    register={register}
                    required={false}
                    error={errors.message}
                    onChange={handelchange}

                  />
                </div>
              </div>
              <div>
                <Typography variant="P_regular_H6" className="mt-3">
                  Upload CV/Resume
                </Typography>
                <label
                  htmlFor="upload"
                  className="w-full flex md:justify-between justify-start  px-4 text-end  border py-3 my-4  border-light-gray rounded-lg"
                >
                  <span>{fileInfo?.name}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="cursor-pointer"
                  >
                    <g clip-path="url(#clip0_464_59057)">
                      <path
                        d="M14.6127 16.9497V8.12714H16.6629C16.9313 8.12714 17.1763 7.97424 17.2944 7.73328C17.4125 7.49231 17.3832 7.20502 17.2188 6.99298L12.5557 0.975586C12.4225 0.80365 12.2173 0.703125 11.9998 0.703125C11.7822 0.703125 11.577 0.80365 11.4439 0.975586L6.7807 6.9928C6.61628 7.20502 6.58698 7.49231 6.70508 7.73328C6.82318 7.97424 7.06818 8.12714 7.33661 8.12714H9.38684V16.9497H14.6127Z"
                        fill="#0176D3"
                      />
                      <path
                        d="M0 19.7631V21.8974C0 22.6743 0.629883 23.304 1.4068 23.304H22.5932C23.3701 23.304 24 22.6743 24 21.8974V19.7631C24 18.9862 23.3701 18.3563 22.5932 18.3563H1.4068C0.629883 18.3563 0 18.9862 0 19.7631Z"
                        fill="#0176D3"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_464_59057">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </label>
                <input
                  name="upload"
                  id="upload"
                  type="file"
                  className="hidden"
                  accept="application/pdf"
                  onChange={handleChange}
                />
                {
                  errtatus && fileInfo.resume.length === 0 && <p className='text-red-500'>Required</p>
                }
              </div>
              {fileError && <p className="text-danger">{fileError}</p>}

              <div className="pb-3 flex">
                <input
                  type="checkbox"
                  required
                  className="border-1 w-5 border-light-gray"
                  onChange={handleCheckboxChange}
                />
                <Typography variant="P_Regular_H6" className="ps-3 ">
                  By using this form you agree with the storage and handling of
                  your data by this website.
                </Typography>
              </div>
              {error && <p className="text-danger">{error}</p>}

              {
                loading ? <Button
                  type="submit"
                  className=" mt-6 inline-flex items-center justify-center w-full px-5 py-3 text-base font-medium text-primary-white transition-all duration-200 bg-primary-blue border border-transparent rounded-xl hover:bg-primary-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-blue"
                >
                  Loading...
                </Button> : <Button
                  type="submit"
                  className=" mt-6 inline-flex items-center justify-center w-full px-5 py-3 text-base font-medium text-primary-white transition-all duration-200 bg-primary-blue border border-transparent rounded-xl hover:bg-primary-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-blue"
                >
                  Submit
                </Button>
              }
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalForm;
