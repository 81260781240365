import React from 'react';
import PropTypes from 'prop-types';

function EmailInput({ label, name, register, required, error, onChange, value }) {
  return (
    <div>
      <label htmlFor={name} className="text-base font-medium text-primary-black">
        {label}
      </label>
      <div className="mt-2">
        {
          register !== undefined ? <>
            <input
              {...register(name, { required })}
              type="email"
              id={name}
              value={value}
              onChange={onChange}
              required
              placeholder={`Enter your ${label.toLowerCase()}`}
              className="block w-full px-4 py-3 text-base text-primary-black bg-white border border-gray-200 rounded-lg focus:outline-none focus:border-blue-600 focus:ring-1 focus:ring-blue-600"
            />
            {error && <span className="text-red-500">This field is required</span>}
          </> : <>
            <input
              type="email"
              id={name}
              value={value}
              onChange={onChange}
              required
              placeholder={`Enter your ${label.toLowerCase()}`}
              className="block w-full px-4 py-3 text-base text-primary-black bg-white border border-gray-200 rounded-lg focus:outline-none focus:border-blue-600 focus:ring-1 focus:ring-blue-600"
            />
          </>
        }

      </div>
    </div>
  );
}

EmailInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  required: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,

};

export default EmailInput;
