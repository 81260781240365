import React from 'react';

import { motion as m } from 'framer-motion';

// ** import images
import mapImg from '@assets/svg/connect/bulb5.jpg';
import headerBgImg from '@assets/Images/contactus/bulb3.jpg';

// ** components
import PageHeader from '@components/PageHeader';
import ContactForm from '@components/ContactForm';
import FooterContactUs from '@components/ContactUs';
import Image from '@src/components/shared/Image';
import Typography from '@src/components/shared/Typography';

// ** import sub page content/components
import Banner from './TeamGlobal';

const header_data = {
  image: headerBgImg,
  title: 'We would love to hear from you!',
  subtitle: '',
};

function ContactUs() {
  return (
    <div>
      <PageHeader data={header_data} />

      {/* connect */}
      <section className="sm:py-16 lg:py-20 xl:py-[1rem]">
        <div className="px-4 mx-auto sm:px-6 lg:px-14 max-w-7xl">
          <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.9, ease: 'easeIn' }}
            className="grid grid-cols-1 lg:grid-cols-2 gap-y-12 lg:gap-x-16 xl:gap-x-36"
          >
            {/* form */}
            <div>
              {/* <Typography
                variant="P_SemiBold_H3"
                className="flex text-primary-black ps-8 "
              >
                Get in touch with us
              </Typography>
              <Typography
                variant="P_Regular_H5"
                className="flex text-mid-black ps-8"
              >
                Our friendly team would love to hear from you.
              </Typography> */}
              <ContactForm />
            </div>
            {/* location */}
            <div className="box-border h-full">
              <div className="flex-1 h-full pt-10">
                <Image
                  src={mapImg}
                  alt=""
                  className="w-full object-cover h-[28rem]"
                />
              </div>
            </div>
          </m.div>
        </div>
      </section>

      <Banner />

      {/* <FooterContactUs /> */}
    </div>
  );
}

export default ContactUs;
