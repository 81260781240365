export default function InputText({
    name,
    type,
    placeholder,
    register,
    error,
  }) {
    return (
      <div className="mt-8 content-center dark:text-dark_text_1 space-y-1">
        <label htmlFor={name} className="text-sm font-bold tracking-wide">
          {placeholder}
        </label>
        <input
          className="block w-full px-4 py-3 text-base text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none focus:border-blue-600 focus:ring-1 focus:ring-blue-600"
          type={type}
          placeholder={placeholder}
          {...register(name)}
        />
        {error && <p className="text-red-400">{error}</p>}
      </div>
    );
  }
  