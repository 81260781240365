import React, { useRef } from 'react';

import { useInView } from 'framer-motion';

// ** import components
import Hero from '@src/components/Hero';

// ** import image
import bg from '@assets/Images/services/Amisphb.jpg'

function HeaderBanner() {

  return (
    <Hero img={bg} status='amisp' headerContent={'AMISP'} content={'Enabling seamless communication between utilities and customer.'} />
     );
}
export default HeaderBanner;
