import React, { useRef } from 'react';

import { motion as m, useInView } from 'framer-motion';

//**import images  */
import Big from '@assets/Images/products/Band3.jpg';
import Bg from '@assets/Images/products/Band4.jpg';
import small from '@assets/Images/products/Band.jpg';

//**import components */
import Image from '@src/components/shared/Image';
import Typography from '@shared/Typography';

import {
  bottom_top_variants,
  top_bottom_variants,
} from '@src/utils/animations';

const contentList1 = [
  'Consumer & Meter data management',
  'Head End System',
  'Meter Data Management',
  'Asset Management',
  'Revenue Management',
  'Request and Complaints',
  'Credit management',
  'Dashboards and Analytics',
  'Notifications and Alerts',
];
const contentList2 = [
  'Consumer & Meter data management',
  'Head End System',
  'Meter Data Management',
  'Asset Management',
  'Revenue Management',
  'Request and Complaints',
  'Credit management',
  'Dashboards and Analytics',
  'Notifications and Alerts',
];
const contentList3 = [
  'Consumer & Meter data management',
  'Head End System',
  'Meter Data Management',
  'Asset Management',
  'Revenue Management',
  'Request and Complaints',
  'Credit management',
  'Dashboards and Analytics',
  'Notifications and Alerts',
];

function SolutionEnSmart() {
  const ref = useRef(null);
  const isInView = useInView(ref);

  return (
    <section ref={ref} className="px-11 bg-primary-white sm:py-4">
      <div className=" mx-auto text-center pb-16">
        <div className="pt-16 flex flex-col text-center overflow-hidden w-fit mx-auto">
          <Typography
            mAnimate={{
              mVariants: bottom_top_variants,
              isInView: isInView,
              mDelay: 0,
            }}
            variant="P_SemiBold_H3"
            className="text-primary-black"
          >
            Solution
          </Typography>
        </div>

        <Typography
          mAnimate={{
            mVariants: bottom_top_variants,
            isInView: isInView,
            mDelay: 0.1,
          }}
          variant="P_Regular_H5"
          className="text-dark-white mt-4"
        >
          SmartEU is an Integrated Enterprise solution comprising of Web Based
          Application, Mobility Solutions and Smart Devices for various
          stakeholders that is useful for Utility Companies, Consumers and
          Outsourced Agencies. Apart from the inbuilt workflows, SmartEU offers
          inbuilt Dashboards and Reporting Framework and helps users to
          generate/export Reports & analyze Data
        </Typography>
      </div>

      <div className="px-4 mx-auto sm:px-6 lg:px-16 max-w-7xl">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-12 lg:gap-x-16 xl:gap-x-[4.6rem]">
          <div className="relative overflow-hidden group lg:order-1 rounded-2xl lg:rounded-3xl">
            <div className="transition-all duration-200 group-hover:scale-110">
              <Image
                animate={{ mDelay: 0.3, isInView }}
                src={small}
                className="object-cover w-full h-full"
              />
            </div>
          </div>
          <div className="lg:order-2">
            <div className=" overflow-hidden ">
              <Typography
                mAnimate={{
                  mVariants: top_bottom_variants,
                  isInView: isInView,
                  mDelay: 0.4,
                }}
                variant="P_SemiBold_H_T"
                className="flex text-primary-blue"
              >
                eSMARTEU (Web Application)
              </Typography>
            </div>
            <div className=" overflow-hidden ">
              <Typography
                mAnimate={{
                  mVariants: top_bottom_variants,
                  isInView: isInView,
                  mDelay: 0.5,
                }}
                variant="P_Medium_H4"
                className="flex text-mid-black"
              ></Typography>
            </div>
            <Typography variant="P_Regular" className="text-light-black">
              <ul>
                {contentList1.map((el, idx) => (
                  <li>
                    <div className=" overflow-hidden">
                      <m.span
                        initial="initial"
                        animate={isInView && 'visible'}
                        custom={idx * 0.09 + 0.6}
                        variants={top_bottom_variants}
                        viewport={{ once: true }}
                        key={idx}
                        className="flex items-center gap-2"
                      >
                        <span className=" w-[5px] h-[5px] bg-black rounded-full block"></span>
                        {el}
                      </m.span>
                    </div>
                  </li>
                ))}
              </ul>
            </Typography>
          </div>
        </div>
      </div>
      <br />
      <div className="px-4 mx-auto sm:px-6 lg:px-16 max-w-7xl">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-12 lg:gap-x-16 xl:gap-x-[4.6rem]">
          <div className="relative overflow-hidden group lg:order-1 rounded-2xl lg:rounded-3xl">
            <div className="transition-all duration-200 ">
              <div className=" overflow-hidden ">
                <Typography
                  mAnimate={{
                    mVariants: top_bottom_variants,
                    isInView: isInView,
                    mDelay: 0.4,
                  }}
                  variant="P_SemiBold_H_T"
                  className="flex text-primary-blue"
                >
                  eSMARTEU (Web Application)
                </Typography>
              </div>
              <div className=" overflow-hidden ">
                <Typography
                  mAnimate={{
                    mVariants: top_bottom_variants,
                    isInView: isInView,
                    mDelay: 0.5,
                  }}
                  variant="P_Medium_H4"
                  className="flex text-mid-black"
                ></Typography>
              </div>
              <Typography variant="P_Regular" className="text-light-black">
                <ul>
                  {contentList2.map((el, idx) => (
                    <li>
                      <div className=" overflow-hidden">
                        <m.span
                          initial="initial"
                          animate={isInView && 'visible'}
                          custom={idx * 0.09 + 0.6}
                          variants={top_bottom_variants}
                          viewport={{ once: true }}
                          key={idx}
                          className="flex items-center gap-2"
                        >
                          <span className=" w-[5px] h-[5px] bg-black rounded-full block"></span>
                          {el}
                        </m.span>
                      </div>
                    </li>
                  ))}
                </ul>
              </Typography>
            </div>
          </div>
          <div className="lg:order-2">
            <div className="relative overflow-hidden group lg:order-1 rounded-2xl lg:rounded-3xl">
              <div className="transition-all duration-200 group-hover:scale-110">
                <Image
                  animate={{ mDelay: 0.3, isInView }}
                  src={Big}
                  className="object-cover w-full h-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="px-4 mx-auto sm:px-6 lg:px-16 max-w-7xl">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-12 lg:gap-x-16 xl:gap-x-[4.6rem]">
          <div className="relative overflow-hidden group lg:order-1 rounded-2xl lg:rounded-3xl">
            <div className="transition-all duration-200 group-hover:scale-110">
              <Image
                animate={{ mDelay: 0.3, isInView }}
                src={Bg}
                className="object-cover w-full h-full"
              />
            </div>
          </div>
          <div className="lg:order-2">
            <div className=" overflow-hidden ">
              <Typography
                mAnimate={{
                  mVariants: top_bottom_variants,
                  isInView: isInView,
                  mDelay: 0.4,
                }}
                variant="P_SemiBold_H_T"
                className="flex text-primary-blue"
              >
                eSMARTEU (Web Application)
              </Typography>
            </div>
            <div className=" overflow-hidden ">
              <Typography
                mAnimate={{
                  mVariants: top_bottom_variants,
                  isInView: isInView,
                  mDelay: 0.5,
                }}
                variant="P_Medium_H4"
                className="flex text-mid-black"
              ></Typography>
            </div>
            <Typography variant="P_Regular" className="text-light-black">
              <ul>
                {contentList3.map((el, idx) => (
                  <li>
                    <div className=" overflow-hidden">
                      <m.span
                        initial="initial"
                        animate={isInView && 'visible'}
                        custom={idx * 0.09 + 0.6}
                        variants={top_bottom_variants}
                        viewport={{ once: true }}
                        key={idx}
                        className="flex items-center gap-2"
                      >
                        <span className=" w-[5px] h-[5px] bg-black rounded-full block"></span>
                        {el}
                      </m.span>
                    </div>
                  </li>
                ))}
              </ul>
            </Typography>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SolutionEnSmart;
