import * as Yup from "yup";

export const contactUsSchema = Yup.object({
    FirstName: Yup.string()
        .required("First Name is required")
        .matches(/^[a-zA-Z_ ]*$/, "No special characters allowed.")
        .min(2, "Name must be between 2 and 16 characters.")
        .max(25, "Name must be between 2 and 16 characters."),
    LastName: Yup.string()
        .required("Last Name is required")
        .matches(/^[a-zA-Z_ ]*$/, "No special characters allowed.")
        .min(2, "Name must be between 2 and 16 characters.")
        .max(25, "Name must be between 2 and 16 characters."),
    Email: Yup.string()
        .required("Email address is required.")
        .email("Invalid email address."),
    Organization: Yup.string().required("Organization is required.").max(64, "Status must be less than 64 characters."),
    Message: Yup.string().max(64, "Status must be less than 64 characters."),
    


});

