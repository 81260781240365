import React from 'react';

// ** import sub page content/components
import HeaderBanner from './HeadBanner';
import OurExperience from '@src/components/Experience';
import Testimonials from '@src/components/Testimonials';
import ContactUs from '@src/components/ContactUs';
import Costumers from '@src/components/Costumers';
import Contact from './Contact';
import ChooseUs from './ChooseUs';
import Validate from './Validate';
import Remote from './Remote'
import ShortInfo from '@src/components/ShortInfo';
import Rewrite from './Rewrite';
import OurServices from '@src/components/OurServices';
import msgThumpSubSvg from '@assets/svg/home/msgThumpSub.svg';
import mobileShopSvg from '@assets/svg/home/mobileShop.svg';
import socialMsgSvg from '@assets/svg/home/socialMsg.svg';
import startGiftSvg from '@assets/svg/home/startGift.svg';
import globalNetworkSvg from '@assets/svg/home/globalNetwork.svg';
import customerSupportSvg from '@assets/svg/home/customerSupport.svg';
const servicesData = [
  {
    img: msgThumpSubSvg,
    title: 'Metering',
    description:
      'Procurement, Installation & Commissioning Services.',
  },
  {
    img: mobileShopSvg,
    title: 'Data Aquisition',
    description:
      'Acquiring data through meters via a communication network.',
  },
  {
    img: socialMsgSvg,
    title: 'Software Platform',
    description:
      'SmartEU Solution - MDAS, MDM, GIS, RMS, WFM, Customer Support and Utility Ops Management.',
  },
  {
    img: startGiftSvg,
    title: 'Tech & Domain Support',
    description:
      'Architecture and Integration Services along with Domain Support to provide best in Class AMI Solution.',
  },
  {
    img: globalNetworkSvg,
    title: 'Data Analytics',
    description:
      'Data Analaysis, Report Generation and Presentation in Dashboard.',
  },
  {
    img: customerSupportSvg,
    title: 'Support',
    description:
      'Focused & Dedicated Energy CoE (EnCoE) for development of Accelerators/  Tools.',
  },
];

function Home() {
  return (
    <div className="">
      <HeaderBanner/>
      <Remote />
      {/* <ShortInfo /> */}
      {/* <OurServices
        servicesData={servicesData}
        title={'AMISP'}
        description={
          'Enable seamless communication between utilities and customer Advanced metering infrastructure (AMI) is an integrated system of smart meters, communications networks, and data management systems that enable two-way communication between utilities and customers. The system provides a number of important functions such as automatic and remote measurement of electricity use, connect and disconnect service, detect tampering, identify and isolate outages and monitor voltage. AMI also enables utilities to offer new time-based rate programs and incentives that encourage customers to reduce peak demand and manage energy consumption and costs.'
        }
      /> */}
      <ChooseUs/>
      <Rewrite />
      {/* <OurExperience flip={true}/> */}
      <Costumers Title="Our Clients" />
      {/* <Testimonials /> */}
      {/* <ContactUs /> */}
    </div>
  );
}

export default Home;
