import React from 'react';
import PropTypes from 'prop-types';

import { motion as m, useInView } from 'framer-motion';
import { bottom_top_variants } from '@src/utils/animations';

function PageHeader({ data }) {
  const { image, title, subtitle } = data;
  return (
    <section
      // style={{
      //   backgroundImage: `url(${image})`,
      //   backgroundSize: '100% 100%',
      //   backgroundRepeat: 'no-repeat',
      // }}
      className=" overflow-hidden relative grid place-items-center"
    >
      <div className="relative top-0 left-0 w-screen h-[55vh]">
        <img
          src={image}
          alt="contact_us_image"
          className=" absolute top-0 left-0 w-full h-full object-cover"
        />
        <span className=" w-full h-full backdropGradient absolute top-0 left-0"></span>
      </div>
      <div className="px-4 z-10 top-[25%] block absolute mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-3xl mx-auto overflow-hidden text-center">
          <div className=" overflow-hidden">
            <m.h1
              initial="initial"
              animate="visible"
              custom={0.4}
              variants={bottom_top_variants}
              className="mt-20 text-3xl font-semibold tracking-tight text-white sm:text-4xl xl:text-5xl sm:tracking-tight"
            >
              {title}
            </m.h1>
          </div>
          <m.p
            initial="initial"
            animate="visible"
            custom={0.5}
            variants={bottom_top_variants}
            className="mt-6 text-sm leading-7 text-white lg:leading-5 lg:text-lg lg:px-20"
          >
            {subtitle}
          </m.p>
        </div>
      </div>
    </section>
  );
}

export default PageHeader;

PageHeader.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
  }).isRequired,
};
