export const left_right_variants = {
  initial: { x: '-100%' },
  slidein: (i) => ({
    x: 0,
    opacity: 1,
    transition: { duration: 1, ease: [0.76, 0, 0.24, 1], delay: i },
  }),
};

export const bottom_top_variants = {
  initial: { y: 100, opacity: 0 },
  visible: (i) => ({
    y: 0,
    opacity: 1,
    transition: { duration: 1, ease: [0.76, 0, 0.24, 1], delay: i },
  }),
};

export const top_bottom_variants = {
  initial: { y: '-150%' },
  visible: (i) => ({
    y: 0,
    transition: { duration: 1, ease: [0.76, 0, 0.24, 1], delay: i },
  }),
  hidden: {
    y: '-150%',
    transition: { duration: 1, ease: [0.76, 0, 0.24, 1] },
  },
};
