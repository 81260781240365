import React from 'react';
import { ReactComponent as Location } from './Location.svg';
import { ReactComponent as LinkOut } from './LinkOut.svg';
import { ReactComponent as Suitcase } from './Suitcase.svg';
import { ReactComponent as StarsIcon } from './StarsIcon.svg';






const withIconProps = (IconComponent) =>
  function ({ size, color, className, ...rest }) {
    return (
      <IconComponent
        width={size}
        height={size}
        fill={color}
        className={className}
        {...rest}
      />
    );
  };

export const LocationIcon = withIconProps(Location);
export const LinkOutIcon = withIconProps(LinkOut);
export const SuitcaseIcon = withIconProps(Suitcase);
export const Stars = withIconProps(StarsIcon);






