import React from 'react';

// ** import components
import Image from '@shared/Image';

function Header1({ bgImg, children }) {
  return (
    <div className="overflow-x-hidden bg-primary-white">
      <section className="relative py-12 sm:py-16 lg:pt-20 xl:pb-0 lg:max-h-[482px] top-0 overflow-hidden">
        <div className="w-full h-full lg:max-h-[482px]   absolute top-0 overflow-hidden">
          <Image src={bgImg} alt="" className="!h-full !object-cover" />
        </div>
        <div className="relative px-4 mx-auto sm:px-6 top-1 lg:px-8 max-w-7xl mb-20">
          <div className="mx-auto text-center">{children}</div>
        </div>
      </section>
    </div>
  );
}
export default Header1;
